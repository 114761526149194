<template>
	<div class="blain-hover-card" :style="{ padding, borderRadius }">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'HoverCard',
	props: {
		borderRadius: {
			type: String,
			default: '6px 6px 0 0'
		},
		padding: String
	}
};
</script>

<style lang="less">
@import '~lessRoot/_mixins.less';
.blain-hover-card {
	background-color: #FFFFFF;
	overflow: hidden;
	position: relative;
	&::after {
		background-color: tint(@CL, 60%);
		content: "";
		position: absolute;
		height: 1px;
		right: 0; bottom: 0; left: 0;
		transition: height 0.2s ease-in;
	}

	&:hover {
		&::after {
			height: 4px;
		}
	}
}
</style>