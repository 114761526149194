import { render, staticRenderFns } from "./ProductListPriceToolTip.vue?vue&type=template&id=67d9489c&scoped=true"
import script from "./ProductListPriceToolTip.vue?vue&type=script&setup=true&lang=ts"
export * from "./ProductListPriceToolTip.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./ProductListPriceToolTip.vue?vue&type=style&index=0&id=67d9489c&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d9489c",
  null
  
)

export default component.exports