import { IAttributeValue, IWorkOrderCustomerInfoResource, IWorkOrderPaymentInfoResource, IWorkOrderSummaryResource, WorkOrderAppointmentInfo, WorkOrderType } from "JS/automotive/auto-service-scheduler/types/session";

export interface ICustomerVehicle {
	id?: number;
	year: string | number;
	make: string;
	model: string;
	trim?: string;
	color?: CustomerVehicleIconColor;
	name?: string;
	prefix?: string;
	iconType: CustomerVehicleIconType;
	isPrimary?: boolean;
	tireSizeDescription: string;
	engine?: string;
}

export interface ISavedVehicle {
	id?: number;
	year: string | number;
	make: string;
	model: string;
	color?: CustomerVehicleIconColor;
	name?: string;
	iconType?: CustomerVehicleIconType;
	engine?: string;
	fitmentMessage?: string;
}

export enum CustomerVehicleIconType {
	Car = 1,
	Van = 2,
	SUV = 3,
	Truck = 4
}

export type CustomerVehicleIconColor =
	| 'default'
	| 'black'
	| 'gray'
	| 'gold'
	| 'red'
	| 'green'
	| 'blue'
	| 'brown'
	| 'white';

export const VehicleIconColorMap: Record<CustomerVehicleIconColor, string> = {
	default: '#656E97',
	black: 'black',
	gray: '#888888',
	gold: '#AD8421',
	red: '#D53310',
	green: '#006733',
	blue: '#165176',
	brown: '#653E00',
	white: 'white',
};

export interface IUpdateVehicleRequest {
	id: number;
	name: string;
	color: string;
	iconType: CustomerVehicleIconType;
}

export interface IAutoPartsVehicleCombinedMake {
	year: number;
	makeID: number;
	truckMakeID: number;
	description: string;
}

export interface IAutoPartsVehicleModel  {
	year: number;
	makeID: number;
	modelID: number;
	description: string;
	isTruck: boolean;
}

export interface IAutoPartsVehicleEngine {
	year: number;
	makeID: number;
	modelID: number;
	engineID: number;
	description: string;
}

export interface IAutoPartsVehicle {
	id?: number;
	year: number;
	makeID: number;
	modelID: number;
	engineID: number;
	isTruck: boolean;
	makeDescription?: string;
	modelDescription?: string;
	engineDescription?: string
}

export interface IAutoStat {
	name: string;
	value: string;
}


export interface IWorkOrderConfirmationResource {
	prePaid: boolean;
	orderId: number;
	orderHash: string;
	type: WorkOrderType;
	primaryProductInfo: IWorkOrderConfirmationProductResource | null;
	workOrderSummary: IWorkOrderSummaryResource;
	customerInfo: IWorkOrderCustomerInfoResource;
	services: IServiceConfirmationResource[];
	appointmentInfo: WorkOrderAppointmentInfo;
	paymentInfo: IWorkOrderPaymentInfoResource;
}

export interface IServiceConfirmationResource {
	name: string,
	icon: string
}

export interface IWorkOrderConfirmationProductResource {
	name: string;
	description: string;
	imageUrl: string;
	imageUrlWebp: string;
	vendorBrand: string;
	selectedAttributes: IAttributeValue[];
}