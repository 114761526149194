<template>
	<div class="reviews-wrapper flex-row" :aria-hidden="inert.toString()">
		<base-button
			:class="['review-stars', {'scroll-disabled': isScrollDisabled}]"
			@click="scrollToReviews"
			:aria-label="starLabel"
			:disabled="isScrollDisabled"
			:style="`--review-stars-font-size: ${starSize}`"
			:tabindex="inert ? '-1' : null"
		>
			<div
				class="review-stars__empty"
				aria-hidden="true"
			>
				<icon name="star-outline" />
				<icon name="star-outline" />
				<icon name="star-outline" />
				<icon name="star-outline" />
				<icon name="star-outline" />
			</div>
			<div
				class="review-stars__filled"
				aria-hidden="true"
				:style="{ width }"
			>
				<icon name="star" />
				<icon name="star" />
				<icon name="star" />
				<icon name="star" />
				<icon name="star" />
			</div>
		</base-button>
		<div class="reviews-amount" v-if="totalReviews">
			<span :class="`span-${color}`">
				{{ reviewsText }}
				<span class="visually-hidden" v-if="shortReviews">
					{{ this.totalReviews !== 1 ? 'Reviews' : 'Review' }}
				</span>
			</span>
		</div>
		<div class="reviews-amount" v-if="!totalReviews && !rating">
			<span>
				(0)
				<span class="visually-hidden">
					{{ this.totalReviews !== 1 ? 'Reviews' : 'Review' }}
				</span>
			</span>
			<link-button
				v-if="blainNumber"
				:class="`span-${color} link-to-modal`"
				@click="showReviewModal"
				:tabindex="inert ? '-1' : null"
			>
				Write a review
			</link-button>
		</div>
	</div>
</template>
<script>
import BaseButton from './BaseButton.vue';
import bugsnagClient from '../global/bugsnag';
import Icon from './Icon.vue';
import LinkButton from './LinkButton.vue';

export default {
	name: "ReviewStars",
	components: {
		BaseButton,
		LinkButton,
		Icon,
	},
	props: {
		blainNumber: String,
		color: {
			type: String,
			default: 'yellows',
		},
		shortReviews: Boolean,
		noParentheses: Boolean,
		starSize: {
			type: String,
			default: '16px'
		},
		totalReviews: {
			type: Number,
			default: 0
		},
		rating: {
			type: Number,
			default: 0
		},
		disableScroll: Boolean,
		inert: Boolean,
	},
	computed: {
		isMobile() {
			return this.$mq === 'mobile';
		},
		reviewsText() {
			return this.shortReviews
				? this.noParentheses ? `${this.totalReviews}` : `(${this.totalReviews})`
				: this.totalReviews > 1
					? `${this.totalReviews.toLocaleString()} Reviews`
					: `${this.totalReviews} Review`;
		},
		width() {
			const roundedRating = Math.floor(this.rating * 2) / 2; //round down to nearest .5 
			const widthPct = (roundedRating / 5) * 100;
			return `${widthPct}%`;
		},
		starLabel() {
			const ratingLabel = (this.totalReviews > 0 ? `Rated ${this.rating} stars` : 'No rating yet');
			return this.disableScroll ? ratingLabel : ratingLabel + ', scroll to reviews';
		},
		isScrollDisabled() {
			return this.disableScroll || this.rating <= 0 || this.inert;
		},
	},
	methods: {
		showReviewModal() {
			if (!this.blainNumber || !window.$BV || !window.$BV.ui) {
				const err = 'Write review was clicked before bazaar voice was loaded';
				bugsnagClient.notify(err);
			}
			const productId = this.blainNumber;
			window.$BV.ui("rr", "submit_review", { productId });
		},
		scrollToReviews() {
			if (this.isScrollDisabled) return;
			const reviewRef = document.getElementById('pdpReviews');
			const scrollTop = reviewRef.offsetTop - 130;
			$("html, body").animate({ scrollTop }, 500);
		},
	}
};
</script>

<style lang="less" scoped>
@import "../../less/_mixins.less";
.reviews-wrapper {
	display: flex;
	max-width: 300px;
}
.reviews-amount {
	font-size: 14px;
	margin-left: 8px;
}
.link-to-modal {
	font-size: 14px;
}
@media screen and (max-width: @mobile) {
	.review-stars {
		font-size: 15px!important;
	}
	.span-yellows {
		font-size: 15px;
	}
}
</style>