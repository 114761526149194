
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import CheckboxLink from './CheckboxLink.vue';

@Component({
	name: 'CheckboxFacet',
	components: {
		CheckboxLink,
	},
})
class CheckboxFacet extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	value: string;

	@Prop({ type: String, required: true })
	label: string;

	@Prop({ type: Boolean })
	isSelected: boolean;

	@Prop({ type: String, required: true })
	href: string;

	@Prop({ type: [String, Boolean], required: true })
	rel: string | false;

	@Prop({ type: Number, required: true })
	count: number;

	/* Computed
	============================================*/

	/* Data
	============================================*/

	/* Methods
	============================================*/
	onChange(e) {
		this.$emit('change', e);
	}
}

export default CheckboxFacet;
