
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { loaderStyleClass } from '../../../../constants/skeleton-loader';
import { SkeletonLoaderType } from '../../../../types/UiComponents';

@Component({
	name: 'SkeletonLoader'
})
export default class SkeletonLoader extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly loaderType: SkeletonLoaderType;

	@Prop({ type: Boolean, default: false })
	readonly detailedLoader: boolean;

	/* Computed
	============================================*/
	get loaderClass(): string {
		return loaderStyleClass[this.loaderType];
	}

}
