
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import VIntersectionObserver from 'Src/js/components/VIntersectionObserver.vue';

@Component({
	name: 'LazyImage',
	components: {
		VIntersectionObserver,
	},
})
export default class LazyImage extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	src: string;

	@Prop({ type: String })
	srcWebp: string;

	@Prop({ type: String })
	alt: string;

	@Prop({ type: String })
	ariaLive: 'assertive' | 'polite' | 'off';

	@Prop({ type: String, default: '' })
	ariaLabel: string;

	@Prop({ type: Boolean, default: true })
	shouldImageTransition: boolean;

	/* Data
	============================================*/
	loaded: boolean = false;
	lazySrc: string = null;
	lazySrcWebp: string = null;

	/* Methods
	============================================*/
	loadImage() {
		if (this.loaded) {
			return;
		}

		this.loaded = true;
		this.lazySrc = this.src;
		this.lazySrcWebp = this.srcWebp;
	}

	/* Watchers
	============================================*/

	// REVIEW:
	// Because we just call load image when the observer component emits 'enter', this isn't smart enough to handle src changes _while the element is still in the viewport_.
	// Either:
	//   Rewrite this component using useIntersectionObserver to have more insight and handle these updates properly
	//   - OR -
	//   Deprecate/remove this component and use some other facility for lazy loading (like the standard loading="lazy" or lazyLoadImages from global/images.ts)
	//
	// For consumers: note that you can still use this component by applying a unique :key to it that will change when image needs to.
	//                that should cause Vue to create a new instance that has all of the state set up properly
	@Watch('src')
	onSourceChange(curSrc: string, prevSrc: string) {
		if (curSrc !== prevSrc) {
			if (!this.shouldImageTransition || !window.IntersectionObserver) {
				this.lazySrc = curSrc;
				this.lazySrcWebp = this.srcWebp;
			} else {
				this.loaded = false;
			}
		}
	}
}
