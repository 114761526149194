import { buildQuery, parseQueryString } from './url';

export function buildPaginationURL(page: number): string {
	const { protocol, host, pathname, search } = window.location;
	const queryParams = parseQueryString(search).filter(param => param.name !== 'pg');

	if (page !== 1) {
		queryParams.push({
			name: 'pg',
			values: [page.toString()],
		});
	}

	let query = buildQuery(queryParams);
	if (query !== '') {
		query = '?' + query;
	}

	return `${protocol}//${host}${pathname}${query}`;
}