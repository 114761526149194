export interface IShippingSummary {
	zip: number;
	price?: string;
	estimatedDeliveryDate?: string;
}

export enum FulfillmentType {
	InStorePickup = 'Pickup',
	LocalDelivery = 'Delivery',
	StandardShipping = 'Ship',
}