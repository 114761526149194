
import BaseButton from 'JS/components/BaseButton.vue';
import Icon from 'JS/components/Icon.vue';
import PriceDisplay from 'JS/components/price/PriceDisplay.vue';
import TransitionExpand from '../../../components/transitions/TransitionExpand.vue';
import TransitionFade from 'JS/components/transitions/TransitionFade.vue';
import UiButton from 'JS/components/UiButton.vue';
import { computed, defineComponent, nextTick, ref, watch } from 'vue';
import { IListableProductJson } from 'JS/types/Product';
import { PropType } from 'vue';
import { useBreakpoints } from 'JS/composables/breakpoint';

const region = 'Tires Selected for Comparison';

export default defineComponent({
	name: 'TireComparison',
	components: {
		BaseButton,
		Icon,
		PriceDisplay,
		UiButton,
		TransitionExpand,
		TransitionFade,
	},
	props: {
		tires: { type: Array as PropType<IListableProductJson[]>, default: () => ([]) },
	},
	setup(props) {
		const { isDesktop } = useBreakpoints();

		const numDummyTires = computed(() => Math.max(3 - props.tires.length, 0));

		const show = ref(false);
		const liveMessage = ref<string | null>(null);
		const container = ref<HTMLElement | null>();

		watch(() => [props.tires.length, container.value, isDesktop.value] as const, ([numTires, container, isDesktop]) => {
			if (!isDesktop || !container || numTires === 0) {
				document.body.style.marginBottom = null;
			} else {
				nextTick(() => {
					document.body.style.marginBottom = `${container.clientHeight}px`;
				});
			}
		});

		watch(() => props.tires.length, (length, previousLength) => {
			if (length > previousLength) {
				show.value = true;
			}

			if (!length) {
				liveMessage.value = `No tires selected. Closing ${region} region.`;
				show.value = false;
				return;
			} else {
				const baseMessage = `${length} tire${length === 1 ? '' : 's'} selected out of 3 allowed. See "${region}" region.`;

				liveMessage.value = !previousLength
					? `Opened tire comparison. ${baseMessage}`
					: baseMessage;
			}
		});


		return {
			container,
			region,
			isDesktop,
			numDummyTires,
			show,
			liveMessage
		};
	},
});
