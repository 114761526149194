import BaseHttpService from './BaseHttpService';
import BlainBaseService from './BlainBaseService';
import EventBusService from './EventBusService';
import LoggerService from './LoggerService';
import { IBaseHttpService } from '../types/Http';
import { IEventBusService } from '../types/EventBus';
import { ILoggerService } from '../types/Logger';
import { ISetLocationResponse } from '../types/Location';

export default class LocationService extends BlainBaseService {

	constructor(
		httpSvc: IBaseHttpService = new BaseHttpService(),
		eventBusService: IEventBusService = new EventBusService(),
		logger: ILoggerService = new LoggerService(),
	) {
		super(eventBusService, logger, httpSvc);
	}

	async setStore(storeNum: number): Promise<ISetLocationResponse> {
		const response = await this._httpSvc.get<ISetLocationResponse>(`/stores/set-json/${storeNum}`);
		if (!response.success) return null;
		return response;
	}

}