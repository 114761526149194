
import CheckboxFacet from './CheckboxFacet.vue';
import LinkButton from '../../../components/LinkButton.vue';
import SearchFacetWrapper from './SearchFacetWrapper.vue';
import Vue from 'vue';
import { buildQuery, parseQueryString } from '../../helpers/url';
import { Component, Prop } from 'vue-property-decorator';
import { FacetDisplayType, ICheckboxProductSearchFacet, IFacetValue } from '../../../types/Search';
import { StyleValue } from 'vue/types/jsx';

@Component({
	name: 'CheckboxFacetGroup',
	components: {
		SearchFacetWrapper,
		CheckboxFacet,
		LinkButton
	}
})
class CheckboxFacetGroup extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Object as () => ICheckboxProductSearchFacet, required: true })
	readonly facet: ICheckboxProductSearchFacet

	@Prop({ type: Boolean })
	readonly initState: Boolean;

	@Prop({ type: String })
	readonly queryString: string;

	/* Computed
	============================================*/

	get facetsToShow() {
		return this.facet.displayType === FacetDisplayType.truncating
			? this.facet.truncateThreshold
			: this.facetValues.length;
	}

	get showMoreLabel() {
		return this.showAllValues ? 'Show Less' : `Show ${this.facetValues.length - this.facetsToShow} More`;
	}

	get facetValues(): IFacetValue[] {
		if (this.facet.isSearch && this.searchQuery != '') {
			return this.facet.values.filter(
				v => v.label.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0
			);
		}
		return this.facet.values;
	}

	get isScrollable(): boolean {
		return this.facet.displayType === FacetDisplayType.scrolling
			&& this.facet.values.length > this.facet.scrollThreshold;
	}

	get isSearchable(): boolean {
		return this.facet.isSearch && this.facet.values.length > this.facet.searchThreshold;
	}

	get containerStyles(): StyleValue {
		if (!this.isScrollable) return null;

		return {
			height: this.facet.scrollHeight + 'px',
		};
	}

	get searchPlaceholder() {
		if (!this.isSearchable) {
			return '';
		}

		const truncateLength = this.$mq === 'mobile' ? 22 : 12;
		const formattedFacetName = this.facet.name
			.toLowerCase()
			.slice(0, truncateLength);

		return `Find a ${formattedFacetName}...`;
	}

	/* Data
	============================================*/

	showAllValues: boolean = false;
	searchQuery: string = '';

	/* Methods
	============================================*/

	toggleVisibility() {
		this.showAllValues = !this.showAllValues;
	}

	getRel(facetValue: IFacetValue): string | false {
		return facetValue.noFollow === 'True' ? 'nofollow' : false;
	}

	getHref(facetValue: IFacetValue): string {
		const queryParams = parseQueryString(this.queryString);

		let param = queryParams.find(param => param.name === this.facet.paramName);

		// the param for this facet may not be in the url yet
		if (!param) {
			param = { name: this.facet.paramName, values: [] };
			queryParams.push(param);
		}

		/**
		 * Reflect the next state of the query string if the facet value was toggled
		 */
		if (facetValue.isSelected) {
			param.values = param.values.filter(value => value !== facetValue.value);
		} else {
			param.values.push(facetValue.value);
		}

		const query = buildQuery(queryParams);

		return window.location.pathname + (query.length ? '?' : '') + query;
	}

	onChange(isSelected: boolean, facetValue: IFacetValue) {
		this.$emit('change', { isSelected, facetValue });
	}
}

export default CheckboxFacetGroup;
