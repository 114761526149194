
import BatteryVehicleFitment from '../batteries/BatteryVehicleFitment.vue';
import ChangeStoreSideMenu from 'JS/components/side-menus/change-store/ChangeStoreSideMenu.vue';
import ChangeStoreSideMenuContents from 'JS/components/side-menus/change-store/ChangeStoreSideMenuContents.vue';
import ProductFacets from './ProductFacets.vue';
import SideMenu from 'JS/components/SideMenu.vue';
import SizeVehicleFitment from './SizeVehicleFitment.vue';
import TireVehicleFitment from '../tires/TireVehicleFitment.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import { IAutoPartsVehicle, ICustomerVehicle } from 'JS/types/Automotive';
import { ITireProductListQuery } from 'JS/types/Catalog';

@Component({
	name: 'FacetsApp',
	components: {
		ProductFacets,
		SideMenu,
		TireVehicleFitment,
		BatteryVehicleFitment,
		SizeVehicleFitment,
		ChangeStoreSideMenu,
		ChangeStoreSideMenuContents
	}
})
class FacetsApp extends Vue {

	/* Computed
	============================================*/
	@Get('hasFacets')
	hasFacets: boolean;

	@Get('searchResult@customerVehicle')
	customerVehicle?: ICustomerVehicle;

	@Get('searchResult@autoPartsVehicle')
	autoPartsVehicle?: IAutoPartsVehicle;

	@Sync('isSideMenuOpen')
	isSideMenuOpen: boolean;

	@Sync('showChangeStore')
	showChangeStore: boolean;

	@Get('isSizeList')
	isSizeList: boolean;

	get isMobile() {
		return this.$mq === 'mobile';
	}

	get wrapper() {
		if (this.isMobile) {
			return {
				component: SideMenu,
				listeners: {
					close: () => {
						if (this.showChangeStore) {
							this.showChangeStore = false;
						} else {
							this.isSideMenuOpen = false;
						}
					},
				},
				props: {
					class: "mobile-facets" ,
					'is-open': this.isSideMenuOpen,
					side: "left",
					size: this.showChangeStore ? 'md' : 'sm',
					menuTitle: 'Filter Results',
					// TODO: measure perf with/without keepAlive here.
					// if there's a measurable difference, the tradeoff
					// would be page load time vs rendering facets every
					// time the menu is opened. rendering facets isn't
					// the fastest thing in the world, esp. when there
					// are nested checkbox facets
					keepAlive: true,
				}
			};
		} else {
			return {
				component: 'div',
				listeners: {},
				props: {},
			};
		}
	}
}
export default FacetsApp;
