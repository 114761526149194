
import ProgressSpinner from 'Src/js/components/ProgressSpinner.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'TireListRecommenderLoader',
	components: {
		ProgressSpinner
	}
})
class TireListRecommenderLoader extends Vue {
}

export default TireListRecommenderLoader;
