
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';

import ClearAllButton from './ClearAllButton.vue';
import SelectedFacet from './SelectedFacet.vue';
import SortSelect from './SortSelect.vue';
import { ISelectedFacetValue } from '../../../types/Search';

@Component({
	name: 'TopBar',
	components: {
		SortSelect,
		ClearAllButton,
		SelectedFacet,
	}
})
export default class TopBar extends Vue {

	@Sync('isSideMenuOpen')
	isSideMenuOpen: boolean;

	@Get('selectedFacets')
	selectedFacets: ISelectedFacetValue[];

	get hasSelectedFacets(): Boolean {
		return this.selectedFacets && this.selectedFacets.length > 0;
	}

	get isMobile() {
		return (this as any).$mq === 'mobile';
	}

	get isDesktop() {
		return (this as any).$mq === 'desktop';
	}
}
