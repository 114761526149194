import { IFacetValue, INestedFacetValue, NestedCheckboxValues } from "JS/types/Search";

/**
 * Transforms an array of facet values from a nestedcheckbox facet into a structure that makes it easy to
 * manipulate and render those values as a tree
 * @param facetValues Initial facet values returned by the backend
 * @returns A lookup map of facetValue.value to INestedCheckboxValue
 */
export function buildNestedCheckboxValues(facetValues: IFacetValue[]): NestedCheckboxValues {
	const nestedFacetValues: INestedFacetValue[] = facetValues.map(facetValue => ({
		...facetValue,
		children: [],
		parent: null,
	}));

	/**
	 * Creates a lookup map of [id => facetValue]
	 * 
	 * We use the value property of INestedFacetValue as an ID. The value string is structured 
	 * like breadcrumbs so there is a reliable pointer to the parent node.
	 * 
	 * Structure of value prop: NEST_LEVEL|SLASH/DELIMITED/PATH
	 * 
	 */
	const valueMap: { [id: string]: INestedFacetValue } = {};
	for (const facetValue of nestedFacetValues) {
		valueMap[facetValue.value] = facetValue;
	}

	for (const facetValue of nestedFacetValues) {
		const { value } = facetValue;

		let parentId: string = '';

		/**
		 * Determine parentID depending on breadcrumbs
		 */
		const breadcrumbs = value.split('/');
		if (breadcrumbs.length > 1) {
			parentId = breadcrumbs.slice(0, breadcrumbs.length - 1).join('/');

			const nestLevel = Number(value.split('|')[0]) - 1; // have to go up a nest level
			parentId = parentId.replace(/^\d+/, nestLevel.toString());
		}

		const parent = valueMap[parentId];

		/**
		 * Sometimes Hawk returns bad data that has a duplicate tree node. If we come across
		 * one, pass over it.
		 */
		if (parent && !parent.children.find(item => item === value)) {
			parent.children.push(facetValue.value);
		}

		facetValue.parent = parent?.value ?? null;
	}

	return valueMap;
}

/**
 * 
 * @param map The nested checkbox lookup map
 * @param facetValue Facet value
 * @returns An array of all the children of `facetValue`
 */
export function getAllChildren(map: NestedCheckboxValues, facetValue: INestedFacetValue): INestedFacetValue[] {
	return facetValue.children.map(c => map[c]).concat(facetValue.children.flatMap(child => getAllChildren(map, map[child])));
}

/**
 * Determines whether any child at arbitrary depth in `facetValue`'s subtree is selected
 * @param map The nested checkbox lookup map
 * @param facetValue Facet value
 * @returns True if a child at arbitrary depth in `facetValue`'s subtree is selected, false otherwise
 */
export function isChildSelected(map: NestedCheckboxValues, facetValue: INestedFacetValue): boolean {
	return getAllChildren(map, facetValue).some(c => c.isSelected);
}

/**
 * Toggles a nested checkbox value and makes the necessary updates to its parent and/or children. Immutability is
 * significant here so that we can use this calculation without causing state updates (ex. generating links for
 * what the URL state _will be_ if a nested checkbox changes) and for easy testability.
 * @param map The nested checkbox lookup map
 * @param value value property of the facet value to toggle
 * @returns The updated copy of `map`
 */
export function toggleNestedFacetValue(map: NestedCheckboxValues, value: string): NestedCheckboxValues {
	const updatedMap: typeof map = {
		...map,
	};

	const facetValue = updatedMap[value];

	const checked = !facetValue.isSelected;
	const parent = facetValue.parent ? updatedMap[facetValue.parent] : null;

	if (checked && parent) {
		updatedMap[parent.value] = {
			...parent,
			isSelected: false,
		};
	}

	for (const child of getAllChildren(updatedMap, facetValue)) {
		if (child.isSelected) {
			updatedMap[child.value] = {
				...child,
				isSelected: false,
			};
		}
	}

	updatedMap[facetValue.value] = {
		...facetValue,
		isSelected: !facetValue.isSelected,
	};

	if (!checked && parent && !isChildSelected(updatedMap, parent)) {
		updatedMap[parent.value] = {
			...parent,
			isSelected: true,
		};
	}

	return updatedMap;
}

/**
 * @param map The nested checkbox lookup map
 * @returns All selected facet values
 */
export function getSelectedNestedFacetValues(map: NestedCheckboxValues): INestedFacetValue[] {
	return Object.values(map).filter(fv => fv.isSelected);
}