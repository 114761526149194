
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'UiChip',
})
class UiChip extends Vue {
	@Prop({ type: String })
	readonly label: string;

	@Prop({ type: String })
	readonly content: string;

	remove() {
		this.$emit('remove');
	}
}
export default UiChip;
