
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { ISelectOption } from '../../../types/UiComponents';

import UiSelect from '../../../components/UiSelect.vue';
import { Get } from 'vuex-pathify';

@Component({
	name: 'SortSelect',
	components: {
		UiSelect,
	}
})
export default class SortSelect extends Vue {
	@Get('sortOptions')
	readonly sortOptions: ISelectOption[];

	get selectedSortOption(): ISelectOption {
		return this.sortOptions.find(s => s.value === this.$store.state.selectedSortOption) ?? this.sortOptions.find(s => s['isDefault'] === true);
	}
	set selectedSortOption(option: ISelectOption) {
		this.$store.dispatch('updateSortOption', option);
	}
}
