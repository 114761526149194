
import BatteryVehicleFitment from '../batteries/BatteryVehicleFitment.vue';
import BottomBar from './BottomBar.vue';
import LinkButton from 'JS/components/LinkButton.vue';
import SizeVehicleFitment from '../facets/SizeVehicleFitment.vue';
import TireListRecommendationSidebar from '../tires/TireListRecommendationSidebar.vue';
import TireRecommendationsBanner from '../tires/recommender/TireRecommendationsBanner.vue';
import TireVehicleFitment from '../tires/TireVehicleFitment.vue';
import TopBar from './TopBar.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IAutoPartsVehicle, ICustomerVehicle } from 'JS/types/Automotive';
import { IListableProductJson } from 'JS/types/Product';
import { jsonCopy } from 'JS/helpers/functions';

@Component({
	name: 'FilterHeaderApp',
	components: {
		TopBar,
		BatteryVehicleFitment,
		BottomBar,
		SizeVehicleFitment,
		TireVehicleFitment,
		LinkButton,
		TireListRecommendationSidebar,
		TireRecommendationsBanner
	}
})
export default class FilterHeaderApp extends Vue {

	/* Props
	============================================*/

	/* Computed
	============================================*/
	@Get('hasFacets')
	hasFacets: boolean;

	@Get('showProducts')
	showProducts: boolean;

	@Get('isLoadingSearchResult')
	isLoading: boolean;

	@Get('isTireList')
	isTireList: boolean;

	@Get('isBatteryList')
	isBatteryList: boolean;

	@Get('isSizeList')
	isSizeList: boolean;

	@Get('searchResult@customerVehicle')
	customerVehicle?: ICustomerVehicle;

	@Get('searchResult@autoPartsVehicle')
	autoPartsVehicle?: IAutoPartsVehicle;

	get show(): boolean {
		return this.hasFacets && (this.isMobile || this.showProducts || this.isLoading);
	}

	get isDesktop(): boolean {
		return this.$mq === 'desktop';
	}

	get isMobile(): boolean {
		return this.$mq === 'mobile';
	}

	/* Data
	============================================*/

	recommendedTires: IListableProductJson[] | null = null;
	showTireRecommender = false;

	/* Methods
	============================================*/

	clearRecommendationResults() {
		this.recommendedTires = null;
		// Ensure the "recs" parameter that keeps track of recommendations is cleared from the url
		const url = new URL(window.location.href);
		if (url.searchParams.has('recs')) {
			url.searchParams.delete('recs');
			window.history.replaceState({}, document.title, url.toString());
		}
	}

	handleHelpButtonPress() {
		this.showTireRecommender = true;
	}

	setRecommendations(tires: IListableProductJson[]) {
		if (!tires?.length) {
			this.recommendedTires = null;
		} else {
			this.recommendedTires = tires;
			this.showTireRecommender = false;
		}
	}

	loadInitialRecommendations() {
		const initialTireRecommendations = (window as any).tireRecommendations;
		if (initialTireRecommendations?.length) {
			this.recommendedTires = jsonCopy(initialTireRecommendations);
			(window as any).tireRecommendations = null;
		}
	}

	/* Lifecycle Hooks
	============================================*/

	created() {
		this.loadInitialRecommendations();
	}

}
