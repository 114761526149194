export type IProductSearchFacet = INestedProductSearchFacet | ICheckboxProductSearchFacet | IRadioButtonProductSearchFacet;

export enum FacetDisplayType {
	default = "default",
	truncating = "truncating",
	scrolling = "scrolling",
}

export interface IBaseSearchFacet {
	isCollapsible: boolean;
	isCollapsedDefault: boolean;
	sortOrder: number;
	isVisible: boolean;
	nofVisible: number;
	height: number;
	facetRangeDisplayType: number;
	preloadChildren: boolean;
	showSliderInputs: boolean;
	ranges?: IFacetRange[];
	facetId: number;
	name: string;
	field: string;
	fieldType: string;
	facetType: string;
	displayType: FacetDisplayType;
	maxCount: number;
	minHitCount: number;
	paramName: string;
	sortBy: string;
	expandSelection: boolean;
	isNumeric: boolean;
	isCurrency: boolean;
	isSearch: boolean;
	scrollHeight: number;
	scrollThreshold: number;
	truncateThreshold: number;
	searchThreshold: number;
	alwaysVisible: boolean;
}

/**
 * This is the type returned by the backend before any transformation occurs (eg. nested checkboxes)
 */
export type IInitialProductSearchFacet = IBaseSearchFacet & {
	facetType: 'checkbox' | 'nestedcheckbox' | 'link';
	values: IFacetValue[];
}

export type ICheckboxProductSearchFacet = IBaseSearchFacet & {
	facetType: 'checkbox';
	values: IFacetValue[];
}

export type INestedProductSearchFacet = IBaseSearchFacet & {
	facetType: 'nestedcheckbox';
	values: NestedCheckboxValues;
}

export type IRadioButtonProductSearchFacet = IBaseSearchFacet & {
	facetType: 'link';
	values: IFacetValue[];
}

export type NestedCheckboxValues = Record<string, INestedFacetValue>;

export interface IFacetValue {
	label: string;
	value: string;
	count: number;
	isSelected: boolean;
	noFollow: string;
}

export interface INestedFacetValue extends IFacetValue {
	parent: string | null;
	children: string[];
}

export interface ISelectedFacetValue {
	facetName: string;
	paramName: string;
	value: IFacetValue;
}

export interface IFacetRange {
	isNumeric: boolean;
	lBound: string;
	uBound: string;
	label: string;
	value: string;
}

export interface IQueryParam {
	name: string;
	values: string[];
}

export const RatingNumberToStar = {
	'5 stars': '★★★★★',
	'4 stars & up': '★★★★☆ & Up',
	'3 stars & up': '★★★☆☆ & Up',
	'2 stars & up': '★★☆☆☆ & Up',
	'1 star & up': '★☆☆☆☆ & Up'
};