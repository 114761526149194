
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'ClearAllButton'
})
class ClearAllButton extends Vue {
	clearSelectedFacets() {
		this.$store.dispatch('deselectAllFacetValues');
	}

	get isMobile(): boolean {
		return this.$mq === 'mobile';
	}
}
export default ClearAllButton;
