
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Get, Sync } from "vuex-pathify";

import PaginationDropdown from "./PaginationDropdown.vue";
import PaginationPageNumbers from "./PaginationPageNumbers.vue";

@Component({
	name: "Pagination",
	components: {
		PaginationPageNumbers,
		PaginationDropdown,
	}
})
class Pagination extends Vue {
	@Sync("page")
	page: number;

	@Get("searchResult@pageSize")
	pageSize: number;

	@Get("searchResult@totalProducts")
	totalSearchResults: number;

	@Get("isLoadingSearchResult")
	isLoading: boolean;

	@Get("showProducts")
	showProducts: boolean;

	@Sync("loadingMessage")
	loadingMessage: string;

	@Get("productListTopEl")
	productListTopEl: HTMLElement;

	isLoadingNewPage = false;

	goToPreviousPage() {
		if (this.isPreviousButtonDisabled) return;

		this.page--;
	}

	goToNextPage() {
		if (this.isNextButtonDisabled) return;

		this.page++;
	}

	changePage(newPage: number) {
		if (1 <= newPage && newPage <= this.numberOfPages) {
			this.page = newPage;
			this.isLoadingNewPage = true;
		}
	}

	get isMobile(): boolean {
		return this.$mq === "mobile";
	}

	get isDesktop(): boolean {
		return this.$mq === "desktop";
	}

	get isPreviousButtonDisabled(): boolean {
		return this.page === 1 || this.isLoading;
	}

	get isNextButtonDisabled(): boolean {
		return (
			this.totalSearchResults / this.page < this.pageSize ||
			this.isLoading
		);
	}

	get previousClasses() {
		return [
			"order-prev",
			"blue-btn",
			{ disabled: this.isPreviousButtonDisabled }
		];
	}

	get numberOfPages(): number {
		return Math.ceil(this.totalSearchResults / this.pageSize);
	}

	@Watch('isLoading') 
	onLoadingChange(isLoading: boolean) {
		if (!this.isLoadingNewPage) return;

		if (isLoading) {
			this.loadingMessage = `Loading page ${this.page}`;
		} else {
			this.loadingMessage = `Page ${this.page} loaded`;
			this.productListTopEl?.focus();
		}
	}
}

export default Pagination;
