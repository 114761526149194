
import Vue, { PropType } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CustomerVehicleIconColor, CustomerVehicleIconType, VehicleIconColorMap } from 'JS/types/Automotive';

@Component({
	name: 'VehicleIcon',
})
class VehicleIcon extends Vue {
	@Prop({ type: String, default: 'default' })
	color: CustomerVehicleIconColor;

	@Prop({ type: Number as PropType<CustomerVehicleIconType>, default: CustomerVehicleIconType.Car })
	type: CustomerVehicleIconType;

	get hexColor(): string {
		return VehicleIconColorMap[this.color] ?? VehicleIconColorMap.default;
	}

	get stroke(): number {
		if (this.color === 'white') {
			return 2;
		}

		return 0;
	}

	get iconTypes() {
		return CustomerVehicleIconType;
	}
}

export default VehicleIcon;
