import { updateMetaTag } from '../../utilities/dom';

export function updateTitle(title: string): void {
	const titleElement = document.querySelector('#product-list-title');

	if (!titleElement) return;

	titleElement.textContent = title;
}

export function updateTotalResultsDisplay(totalProducts: number): void {
	if (typeof totalProducts !== 'number') return;

	const totalResultsDisplay = document.querySelector('.product-list-total-results');

	if (totalResultsDisplay) {
		totalResultsDisplay.textContent = `${totalProducts.toLocaleString()} Result${totalProducts === 1 ? '' : 's'}`;
	}
}

export function updateMetaDescription(metaDescription: string): void {
	updateMetaTag('description', metaDescription);
	updateMetaTag('og:description', metaDescription, 'property');
}

export function updateMetaKeywords(keywords: string): void {
	updateMetaTag('keywords', keywords);
}

export function updateMetaRobots(robotsText: string): void {
	updateMetaTag('robots', robotsText);
}

export function updateTrackingId(trackingId: string): void {
	let trackingEl = document.getElementById('hdnhawktrackingid') as HTMLInputElement;

	if (!trackingEl) {
		trackingEl = document.createElement('input');
		trackingEl.type = 'hidden';
		trackingEl.id = 'hdnhawktrackingid';
		document.body.appendChild(trackingEl);
	}

	trackingEl.value = trackingId;
}