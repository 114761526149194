import { IInitialProductSearchFacet, IQueryParam } from '../../types/Search';
import { parseQueryString } from './url';

export default {

	preselectFacets(facets: IInitialProductSearchFacet[], query: string): IInitialProductSearchFacet[] {
		const facetsCopy: IInitialProductSearchFacet[] = JSON.parse(JSON.stringify(facets));
		const queryParams: IQueryParam[] = parseQueryString(query);

		facetsCopy.forEach(facet => {
			const facetQueryParam = queryParams.find(pair => pair.name === facet.paramName);

			if (!facetQueryParam) return;

			facet.values.forEach(value => {
				if (facetQueryParam.values.some(v => v === value.value)) {
					value.isSelected = true;
				};
			});
		});

		return facetsCopy;
	},

	buildQueryWithConfig(query: string, currentPath: string): string {
		const unicodedPath = currentPath.replace(/\//g, '%2F');
		return query + `${query === '' ? '?' : '&'}ajax=1&json=1&lpurl=${unicodedPath}`;
	},
};