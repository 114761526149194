import { IIncomingStockInfo } from "../types/PDP";

export interface HasIncomingStock {
	incomingStock: IIncomingStockInfo[] | null;
}

export function getMaxQuantityExceeded(product: HasIncomingStock, qty: number): boolean {
	const stock = getTotalIncoming(product);
	const amountAvailable = Math.max(0, stock);
	return !getOutOfStock(product) && amountAvailable < qty;
}

export function getTotalIncoming(product: HasIncomingStock): number {
	return product.incomingStock?.reduce((incoming, { qty }) => incoming + qty, 0) ?? 0;
}

export function getOutOfStock(product: HasIncomingStock): boolean {
	return getTotalIncoming(product) === 0;
}

export function getAmountAvailable(product: HasIncomingStock): number {
	const stock = getTotalIncoming(product);
	return Math.max(0, stock);
}

export function getNextAvailableDate(product: HasIncomingStock, quantityNeeded: number): string {
	if (!product.incomingStock) {
		return null;
	}
	const date = new Date();
	let maxDelay = null;
	let available = 0;
	for (const stock of product.incomingStock) { // assumes list ordered by delay ascending
		maxDelay = stock.delayInDays;
		available += stock.qty;
		if (available >= quantityNeeded) {
			break;
		}
	}

	if (available < quantityNeeded) {
		return null;
	}

	if (maxDelay == 1) {
		return "Available Tomorrow";
	}

	date.setDate(date.getDate() + maxDelay);
	const dayOfWeek = date.toLocaleDateString('en-us', { weekday: "short" });
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const dateFormatted = `${dayOfWeek}. ${month}/${day}`;
	return "Available " + dateFormatted;
}