
import Vue, { PropType } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { INestedFacetValue, INestedProductSearchFacet, IQueryParam } from '../../../types/Search';

import CheckboxLink from './CheckboxLink.vue';
import { buildQuery, parseQueryString } from '../../helpers/url';
import { isChildSelected, toggleNestedFacetValue } from 'JS/catalog/helpers/nested-facets';

@Component({
	name: 'NestedCheckboxFacet',
	components: {
		CheckboxLink,
	},
})
class NestedCheckbox extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	facetParamName: string;

	@Prop({ type: Object as PropType<INestedProductSearchFacet>, required: true })
	facet: INestedProductSearchFacet;

	@Prop({ type: Object as () => INestedFacetValue, required: true })
	facetValue: INestedFacetValue;

	@Prop({ type: String })
	queryString: string;

	/* Computed
	============================================*/

	get hasSelectedChild(): boolean {
		return isChildSelected(this.facet.values, this.facetValue);
	}

	get rel(): string | false {
		return this.facetValue.noFollow === 'True' ? 'nofollow' : false;
	}

	get href(): string {
		const queryParams: IQueryParam[] = parseQueryString(this.queryString);
		let param: IQueryParam = queryParams.find(param => param.name === this.facetParamName);

		// the param for this facet may not be in the url yet
		if (!param) {
			param = { name: this.facetParamName, values: [] };
			queryParams.push(param);
		}

		const facetsWhenToggled = toggleNestedFacetValue(this.facet.values, this.facetValue.value);
		param.values = Object.values(facetsWhenToggled).filter(f => f.isSelected).map(f => f.value);

		const query: string = buildQuery(queryParams);
		return window.location.pathname + (query.length ? '?' : '') + query;
	}

	get children(): INestedFacetValue[] {
		return this.facetValue.children.map(c => this.facet.values[c]);
	}

	onChange() {
		this.$emit('change', { facet: this.facet, value: this.facetValue.value });
	}
}

export default NestedCheckbox;
