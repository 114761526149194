import ajax from '../../utilities/ajax';
import bugsnagClient from '../../global/bugsnag';
import FacetHelper from '../helpers/FacetHelper';
import globalData from '../../global/window-data';
import pathify, { make } from 'vuex-pathify';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { buildNestedCheckboxValues, getSelectedNestedFacetValues, toggleNestedFacetValue } from '../helpers/nested-facets';
import { buildQuery, extractQueryParamValues, parseQueryString } from '../helpers/url';
import { HawkSearchType, IHawkSearchWebResultJson } from 'JS/types/Catalog';
import { ICheckboxProductSearchFacet, IFacetValue, IInitialProductSearchFacet, INestedProductSearchFacet, IProductSearchFacet, IQueryParam, IRadioButtonProductSearchFacet, ISelectedFacetValue } from '../../types/Search';
import { IEpsilonAdsResource } from 'JS/sponsored/sponsored-types';
import { ILocalStoreJson } from '../../types/LocalStore';
import { ISelectOption } from '../../types/UiComponents';
import { ITaxonomyViewModel } from '../../types/Taxonomy';
import { stringIsNullOrWhiteSpace } from '../../helpers/functions';

/*============================================================
 == Config
/============================================================*/

pathify.options.mapping = 'simple';

const externalParamNames = [
	'keyword',
	'hawkspellcheck',
	'customerVehicleId',
	'sizeDescription',
	'sectionWidth',
	'aspectRatio',
	'rimSize',
];

/*============================================================
 == State
/============================================================*/

class State {
	facets: IProductSearchFacet[] = [];
	searchResult: IHawkSearchWebResultJson | null = null;
	epsilonAds: IEpsilonAdsResource = { sponsoredSearchResults: null, bannerXAdTop: null };
	page: number = +extractQueryParamValues(window.location.search, 'pg') || 1;
	isLoadingSearchResult = false;
	categories: ITaxonomyViewModel[] = [];
	isSideMenuOpen: boolean = false;
	showChangeStore: boolean = false;
	selectedSortOption: string = '';
	isLoadingAddToCart: string = '';

	bopusOnly: boolean = extractQueryParamValues(window.location.search, 'bopus')[0] === '1';

	localStore: ILocalStoreJson = globalData.localStore;

	// not storing the full object because that seemed to cause recursion errors in vue with computed properties
	productsInCart: Set<string> = globalData.cartSummary.productsInCart.reduce((set, product) => {
		set.add(product.blainNumber);
		if (product.groupID) {
			set.add(product.groupID.toString());
		}
		return set;
	}, new Set<string>());
	cartHasDeliveryItems: boolean = globalData.cartSummary.hasDeliveryItems;
	cartHasPickupItems: boolean = globalData.cartSummary.hasPickupItems;
	cartHasItemsToShip: boolean = globalData.cartSummary.hasItemsToShip;

	currentRequest: Promise<void> = null;

	/* Accessibility helper state */
	loadingMessage = ''; // read out when loading/loaded search result
	productListTopEl: HTMLElement = null; // element to focus after pagination
}

export type CatalogState = State;

const state = new State();

/*============================================================
 == Mutations
/============================================================*/

const mutations: MutationTree<State> = {
	...make.mutations(state),
	facets(state, newFacets: IInitialProductSearchFacet[]) {
		state.facets = newFacets.map(facet => {
			if (facet.facetType === 'nestedcheckbox') {
				return {
					...facet,
					values: buildNestedCheckboxValues(facet.values),
				} as INestedProductSearchFacet;
			} else if (facet.facetType === 'link') {
				return facet as IRadioButtonProductSearchFacet;
			} else {
				return facet as ICheckboxProductSearchFacet;
			}
		});
	}
};

/*============================================================
 == Actions
/============================================================*/

const actions: ActionTree<State, State> = {

	init({ commit, dispatch }, vm) {
		const { categories, searchResult, epsilonAds } = vm;
		if (categories) commit('categories', categories);
		if (epsilonAds) commit('epsilonAds', epsilonAds);
		if (searchResult) {
			if (searchResult.location?.length) {
				return window.location.replace(searchResult.location);
			}

			let queryParams = parseQueryString(window.location.search);

			const keywordParam = queryParams.find(p => p.name === 'keyword');
			if (searchResult.keyword && keywordParam && searchResult.keyword !== keywordParam.values[0]) {
				keywordParam.values[0] = searchResult.keyword;
			}

			const sortParam = queryParams.find(p => p.name === 'sort');
			if (searchResult.sorting.value) {
				const hawkDefault = searchResult.sorting.items.find(i => i.isDefault);

				if (sortParam) {
					if (sortParam.values[0] === hawkDefault?.value) {
						// sort param is default, remove from query string
						queryParams = queryParams.filter(p => p.name !== 'sort');
					} else {
						// update sort param to match what we got back from hawk
						sortParam.values[0] = searchResult.sorting.value;
					}
				} else {
					// no sort value in query string and hawk value is not default
					if (hawkDefault?.value !== searchResult.sorting.value) {
						queryParams.push({
							name: 'sort',
							values: [ searchResult.sorting.value ]
						});
					}
				}
			}

			let query = buildQuery(queryParams);
			if (query !== '') query = '?' + query;
			window.history.replaceState(window.history.state, '', window.location.origin + window.location.pathname + query);

			dispatch('preselectSort');
			commit('searchResult', searchResult);
			dispatch('preselectFacets', searchResult.facets);
			dispatch('updateGtmDataLayer', searchResult);
		}
	},

	updateGtmDataLayer({}, searchResult: IHawkSearchWebResultJson) {
		window.blainGtmDataLayer.push({ 'searchResult': null }); // searchResult needs to be reset first to avoid conflicts
		window.blainGtmDataLayer.push({
			'searchResult': searchResult,
			'event': "product_list_update"
		});
	},

	preselectSort({ commit, state }) {
		const sortValue = extractQueryParamValues(window.location.search, 'sort')[0];
		if (sortValue) {
			commit('selectedSortOption', sortValue);
		} else {
			commit('selectedSortOption', '');
		}
	},

	preselectFacets({ commit }, facets: IInitialProductSearchFacet[]) {
		const query = window.location.search;

		let preselectedFacets = facets;

		if (query !== '') {
			preselectedFacets = FacetHelper.preselectFacets(facets, query);
		}

		commit('facets', preselectedFacets);
	},

	searchProducts({ commit, dispatch, state }, query: string) {
		commit('isLoadingSearchResult', true);

		const ajaxQuery = FacetHelper.buildQueryWithConfig(query, window.location.pathname);
		const request = dispatch('search', ajaxQuery)
			.then(updateResult => {
				if (!updateResult || request !== state.currentRequest) return;
				const { searchResult, epsilonAds } = updateResult;
				
				if (searchResult.location?.length) {
					return window.location.replace(searchResult.location);
				}

				const queryParams = parseQueryString(window.location.search);

				const keywordParam = queryParams.find(p => p.name === 'keyword');
				if (searchResult.keyword && keywordParam && searchResult.keyword !== keywordParam.values[0]) {
					keywordParam.values[0] = searchResult.keyword;
				}

				let query = buildQuery(queryParams);
				if (query !== '') query = '?' + query;
				window.history.replaceState(window.history.state, '', window.location.origin + window.location.pathname + query);

				commit('searchResult', searchResult);
				commit('epsilonAds', epsilonAds);

				if (searchResult.products?.length) {
					commit('facets', FacetHelper.preselectFacets(searchResult.facets, query));
				}
				commit('isLoadingSearchResult', false);

				// When paging or faceting, push the results to Google Tag Manager
				dispatch('updateGtmDataLayer', searchResult);

			}).catch(err => {
				bugsnagClient.notify(err);
				commit('isLoadingSearchResult', false);
			});

		commit('currentRequest', request);
	},

	search({ getters }, query) {
		if (getters.isTireList) {
			return  ajax.get(`/a/tires/list/${query}`);
		} else if (getters.isBatteryList) {
			return  ajax.get(`/a/batteries/list/${query}`);
		} else {
			return ajax.get(`/products/a/search/${query}`);
		}
	},

	// NOTE: this mutates nested checkbox values. Ideally, it wouldn't, but the behavior works as expected here and it shouldn't cause problem.
	// we rebuild the facets after network response anyway
	updateFacetValue({ dispatch }, data) {
		const facetValue = data.facetValue as IFacetValue;
		const isSelected = data.isSelected as boolean;

		dispatch('resetPage');

		facetValue.isSelected = isSelected;
	},

	deselectFacetValue({ dispatch }, facetValue: IFacetValue) {
		dispatch('updateFacetValue', {
			facetValue,
			isSelected: false,
		});
	},

	deselectAllFacetValues({ dispatch, getters }) {
		const selectedFacets: ISelectedFacetValue[] = getters.selectedFacets;

		selectedFacets.forEach(({ value }) => dispatch('deselectFacetValue', value));
	},

	updateBopusOnly({ commit, dispatch }, checked: boolean) {
		commit('bopusOnly', checked);
		dispatch('resetPage');
	},

	updateSortOption({ commit, dispatch }, option: ISelectOption) {
		const hawkDefault = state.searchResult?.sorting.items.find(i => i.isDefault);

		if (state.selectedSortOption === '') {
			if (hawkDefault?.value !== option.value) {
				// default is currently selected, changing to non-default
				commit('selectedSortOption', option.value);
				dispatch('resetPage');
			}
		} else if (state.selectedSortOption !== option.value) {
			// default is not currently selected
			if (hawkDefault?.value !== option.value) {
				// select a differing non-default
				commit('selectedSortOption', option.value);
				dispatch('resetPage');
			} else {
				// selecting the default
				commit('selectedSortOption', '');
				dispatch('resetPage');
			}
		}
	},

	resetPage({ state, commit }) {
		if (state.page > 1) {
			commit('page', 1);
		}
	},

	updateNestedCheckboxFacet({ dispatch }, { facet, value }: { facet: INestedProductSearchFacet, value: string }) {
		const updatedNestedFacetValues = toggleNestedFacetValue(facet.values, value);

		dispatch('resetPage');

		facet.values = updatedNestedFacetValues;
	},

	handleRadioButtonFacet({ dispatch }, { facet, facetValue }: { facet: IRadioButtonProductSearchFacet, facetValue: IFacetValue }) {
		facet.values.forEach(fv => {
			if (fv.isSelected && fv.value !== facetValue.value) {
				dispatch('deselectFacetValue', fv);
			}
		});

		dispatch('updateFacetValue', {
			facetValue,
			isSelected: true,
		});

		dispatch('resetPage');
	}
};

/*============================================================
 == Actions
/============================================================*/

const getters: GetterTree<State, State> = {
	selectedFacets(state): ISelectedFacetValue[] {
		return state.facets.reduce((selectedValues: ISelectedFacetValue[], facet: IProductSearchFacet) => {
			const values = facet.facetType === 'nestedcheckbox'
				? getSelectedNestedFacetValues(facet.values)
				: facet.values;

			const selectedInFacet: ISelectedFacetValue[] = values
				.filter(facetValue => facetValue.isSelected)
				.map(value => ({
					facetName: facet.name,
					paramName: facet.paramName,
					value,
				}));

			return selectedValues.concat(selectedInFacet);
		}, [] as ISelectedFacetValue[]);
	},

	queryFacets(_state, getters): IQueryParam[] {
		const selectedFacets: ISelectedFacetValue[] = getters.selectedFacets;

        type QueryFacetLookupMap = {
        	[paramName: string]: IQueryParam
        }

        const queryFacetMap: QueryFacetLookupMap = selectedFacets.reduce((map: QueryFacetLookupMap, selectedFacet) => {
        	let queryFacet = map[selectedFacet.paramName];

        	if (!queryFacet) {
        		queryFacet = {
        			name: selectedFacet.paramName,
        			values: [],
        		};
        	}

        	queryFacet.values.push(selectedFacet.value.value);

        	return {
        		...map,
        		[selectedFacet.paramName]: queryFacet,
        	};
        }, {});

        const queryFacets = Object.keys(queryFacetMap).map(key => queryFacetMap[key]);

        return queryFacets;
	},

	isBatteryList(state): boolean {
		return state.searchResult != null && state.searchResult.searchType == HawkSearchType.Batteries;
	},

	isTireList(state): boolean {
		return state.searchResult != null && state.searchResult.searchType == HawkSearchType.Tires;
	},

	isSizeList(state): boolean {
		const { sectionWidth, aspectRatio, rimSize } = state.searchResult?.tireQueryResult?.queryParameters ?? {};

		if (!sectionWidth || !aspectRatio || !rimSize) {
			return false;
		}

		return true;
	},

	queryString(state, { queryFacets }): string {
		// TODO: need to come up with a better way to handle "external" (non-facet-controlled) params and rethink
		// the way we build the query string
		// Right now, if we hit a prod list and there are both external and facet params applied and the external
		// params appear before the query params in the query string, they will be moved to the end. That adds
		// an extraneous history entry. I bet there are other cases where this might happen.

		// need to retrieve the current keyword from the query string since it may have been replaced after initialization
		const externalParams = parseQueryString(window.location.search)
			.filter(param => externalParamNames.indexOf(param.name) !== -1);

		const queryParams = queryFacets.concat(externalParams) as IQueryParam[];

		const hawkDefault = state.searchResult?.sorting.items.find(i => i.isDefault);
		if (state.selectedSortOption !== '' && state.selectedSortOption !== hawkDefault.value) {
			// non-default sort option is selected, put it in the query string
			queryParams.push({
				name: 'sort',
				values: [
					state.selectedSortOption
				]
			});
		}

		if (state.page > 1) {
			queryParams.push({
				name: 'pg',
				values: [state.page.toString()],
			});
		}

		if (state.bopusOnly) {
			queryParams.push({
				name: 'bopus',
				values: ['1'],
			});
		}

		const query = buildQuery(queryParams);

		if (stringIsNullOrWhiteSpace(query)) return query;

		return '?' + query;
	},

	showProducts(state): boolean {
		return state.searchResult && state.searchResult.includeProducts;
	},

	facetsHaveCategories(state): boolean {
		return state.facets && state.facets.some(f => f.name.toLowerCase() === 'category');
	},

	hasFacets(state): boolean {
		return state.facets && state.facets.length > 0;
	},

	sortOptions(state): ISelectOption[] {
		return state.searchResult?.sorting.items.map(x => ({
			title: x.label,
			value: x.value,
			'isDefault': x.isDefault
		})) ?? [];
	}
};

/*============================================================
 == Store
/============================================================*/

const storeOptions = {
	state,
	mutations,
	actions,
	getters,
	plugins: [
		pathify.plugin
	]
};

export default storeOptions;
