
import BCheckboxBase from './BCheckboxBase';
import { Component, Mixins } from 'vue-property-decorator';

@Component({ name: 'BSwitch' })
class BSwitch extends Mixins(BCheckboxBase) {}

export default BSwitch;


//TODO: Open to refactoring, if I am still able to extend mixins after the fact.


