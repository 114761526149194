import { ICustomerVehicle } from './Automotive';
import { IInitialProductSearchFacet } from './Search';
import { IListableProductJson, IProductSwatch } from './Product';

export interface IHawkSearchWebResultJson {
	fallbackProducts: IListableProductJson[];
	success: boolean;
	containsResults: boolean;
	horizontal: boolean;
	isTireFinder: boolean;
	products: IListableProductJson[];
	swatches: Record<string, IProductSwatch[]>;
	facetHtml: string;
	facets: IInitialProductSearchFacet[];
	location: string;
	didYouMeanHtml: string;
	trackingId: string;
	metaRobots: string;
	headerTitle: string;
	metaDescription: string;
	metaKeywords: string;
	relCanonical: string;
	keyword: string;
	original: string;
	didYouMean: string;
	bannerTopHtml: string;
	bannerMobileTopHtml: string;
	bannerBottomHtml: string;
	bannerTopLeftHtml: string;
	bannerBottomLeftHtml: string;
	featuredItems: IListableProductJson[];
	pageNum: number;
	pageSize: number;
	sortOrder: string;
	totalProducts: number;
	originalRequestUrl: Record<string, string>;
	relaxedSearch: boolean;
	redirectedSearch: boolean;
	includeProducts: boolean;
	localStoreNum: number;
	tireList: boolean;
	searchType: HawkSearchType;
	tireQueryResult: ITireProductListQueryResult;
	customerVehicle?: ICustomerVehicle;
	canShowStock: boolean;
	listID: string;
	listName: string;
	categoryImages: Record<string, IImageUrls>;
	sorting: IHawkResultSorting;
}

export enum HawkSearchType {
	Default,
	Tires,
	Batteries
}

export interface ITireAttributeResult {
	comparisonData: ITireAttributeValue[];
	dataAttributes: ITireAttributeValue[];
}

export interface ITireAttributeValue {
	blainNumber: string;
	attributeDescription: string;
	attributeValue: string;
}

export interface ITireProductListQuery {
	pg: number;
	pageSize: number;
	sort: string;
	customerVehicleId: number | null;
	sizeDescription: string;
	sectionWidth: string;
	aspectRatio: string;
	rimSize: string;
	brands: string;
	warrantyCodes: string;
	speedRatings: string;
	vehicleType: string;
}

export interface ITireProductListQueryResult {
	totalProducts: number;
	blainNumbers: string[];
	vendorBrands: ITireVendorBrandFacet[];
	speedRatings: ITireSpeedRatingFacet[];
	warranties: ITireWarrantyFacet[];
	vehicleTypes: ITireVehicleTypeFacet[];
	queryParameters: ITireProductListQuery;
}

// TODO: Delete once we're sure we have all the correct properties on the new model
// export interface ITireQueryResult {
// 	totalProducts: number;
// 	blainNumbers: string[];
// 	products: IListableProductJson[];
// 	vehicleTypes: ITireVehicleTypeFacet[];
// 	vendorBrands: ITireVendorBrandFacet[];
// 	speedRatings: ITireSpeedRatingFacet[];
// 	warranties: ITireWarrantyFacet[];
// 	baseUrl: string;
// 	refreshUrl: string;
// 	vendorBrand: string;
// 	yourVehicle: string;
// 	tireSize: string;
// 	queryType: TireQueryType;
// 	selectedSpeedRating: ITireSpeedRatingFacet;
// 	selectedWarrantyFacet: ITireWarrantyFacet;
// 	selectedVendorBrandFacet: ITireVendorBrandFacet;
// 	selectedVehicleTypeFacet: ITireVehicleTypeFacet;
// 	originalStandardRatingFacets: ITireOriginalRatingFacet[];
// 	originalOptionalRatingFacets: ITireOriginalRatingFacet[];
// 	brandPage: boolean;
// }

export interface ITireVehicleTypeFacet {
	vehicleType: string;
	numProducts: number;
}

export interface ITireVendorBrandFacet {
	vendorBrand: string;
	numProducts: number;
}

export interface ITireSpeedRatingFacet {
	speedRating: string;
	speedRatingStr: string;
	speedRatingNum: number;
	numProducts: number;
}

export interface ITireWarrantyFacet {
	warrantyCode: string;
	warrantyStr: number;
	numProducts: number;
}

export enum TireQueryType {
	None,
	Vehicle,
	VehicleType,
	TireSize
}

export interface ITireOriginalRatingFacet {
	sectionWidth: number;
	aspectRatio: number;
	rimSize: number;
	speedRating: string;
}


export interface IBatteryProductListQueryResult {
	totalProducts: number;
	blainNumbers: string[];
	warranties: ITireWarrantyFacet[];
	queryParameters: IBatteryProductListQuery;
}

export interface IBatteryProductListQuery {
	pg: number;
	pageSize: number;
	sort: string;
	makeID: number;
	modelID: number;
	year: number;
	engineID: number;
	groupSize: string;
	warrantyCodes: string;
}

export interface IImageUrls {
	imageUrl: string;
	imageUrlWebP: string;
}

export interface IHawkResultSorting {
	value: string;
	items: IHawkResultItem[];
}

export interface IHawkResultItem {
	label: string;
	value: string;
	pageSize: number;
	isDefault: boolean;
	selected: boolean;
}