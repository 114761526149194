
import FormFieldWrapper from '../../../components/forms/FormFieldWrapper.vue';
import IconComponent from 'JS/components/Icon.vue'; // alias because BaseFormField has an icon() and that makes typescript unhappy
import { Component, Prop } from 'vue-property-decorator';
import { createBaseFormField } from 'JS/components/forms/BaseFormField';

@Component({
	name: 'Checkbox',
	components: {
		FormFieldWrapper,
		IconComponent
	},
})
class Checkbox extends createBaseFormField({ type: Boolean, required: true }) {
	@Prop({ type: Boolean })
	value: boolean;

	@Prop({ type: String })
	label: string;

	@Prop({ type: Boolean })
	readonly disabled: boolean;

	@Prop({ type: Boolean })
	readonly indeterminate: boolean;

	@Prop({ type: String, required: true })
	readonly href: string;

	@Prop({ type: [String, Boolean], required: true })
	readonly rel: string | false;

	handleChange(event) {
		if (this.disabled) return;
		this.$emit('change', !this.value);
		this.$emit('input', !this.value);
	}

	get wrapperPropsExtended() {
		return {
			...this.wrapperProps,
			hideLabel: true
		};
	}

	get ariaChecked(): 'true' | 'false' | 'mixed' {
		if (this.indeterminate) return 'mixed';

		return this.value.toString() as 'true' | 'false';
	}
}

export default Checkbox;
