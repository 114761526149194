
import NestedCheckboxFacet from './NestedCheckboxFacet.vue';
import SearchFacetWrapper from './SearchFacetWrapper.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { INestedFacetValue, INestedProductSearchFacet } from '../../../types/Search';

@Component({
	name: 'NestedCheckboxFacetGroup',
	components: {
		SearchFacetWrapper,
		NestedCheckboxFacet
	}
})
class NestedCheckboxFacetGroup extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Object as () => INestedProductSearchFacet, required: true })
	readonly facet: INestedProductSearchFacet;

	@Prop({ type: Boolean })
	readonly initState: Boolean;

	@Prop({ type: String })
	readonly queryString: string;

	get topLevelFacetValues(): INestedFacetValue[] {
		return Object.values(this.facet.values).filter(fv => !fv.parent);
	}
}

export default NestedCheckboxFacetGroup;

