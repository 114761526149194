
import BopusOnlyFilter from './BopusOnlyFilter.vue';
import CategoryList from './CategoryList.vue';
import CheckboxFacetGroup from './CheckboxFacetGroup.vue';
import LinkButton from 'JS/components/LinkButton.vue';
import NestedCheckboxFacetGroup from './NestedCheckboxFacetGroup.vue';
import RadioButtonFacetGroup from './RadioButtonFacetGroup.vue';
import SearchFacetWrapper from './SearchFacetWrapper.vue';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IFacetValue, INestedFacetValue, INestedProductSearchFacet, IProductSearchFacet, IRadioButtonProductSearchFacet } from '../../../types/Search';
import { ILocalStoreJson } from 'JS/types/LocalStore';
import { ITaxonomyViewModel } from 'JS/types/Taxonomy';

@Component({
	name: 'ProductFacets',
	components: {
		CheckboxFacetGroup,
		CategoryList,
		LinkButton,
		NestedCheckboxFacetGroup,
		BopusOnlyFilter,
		SearchFacetWrapper,
		RadioButtonFacetGroup,
	}
})
class ProductFacets extends Vue {

	/* Computed
	============================================*/

	@Get('facets')
	facets: IProductSearchFacet[];

	@Get('isTireList')
	isTireList: boolean;

	@Get('categories')
	categories: ITaxonomyViewModel[];

	@Get('facetsHaveCategories')
	facetsHaveCategories: boolean;

	@Get('localStore')
	localStore: ILocalStoreJson;

	@Get('queryString')
	queryString: string;

	get visibleFacets() {
		return this.facets.filter(f => f.field !== "subcat_id");
	}

	get bopusOnly() {
		return this.$store.state.bopusOnly;
	}

	set bopusOnly(checked: boolean) {
		this.$store.dispatch('updateBopusOnly', checked);
	}

	handleCheckboxChange({ facetValue, isSelected }: { facetValue: INestedFacetValue, isSelected: boolean }) {
		this.$store.dispatch('updateFacetValue', {
			facetValue: facetValue,
			isSelected,
		});
	}

	handleNestedCheckboxChange({ facet, value }: { facet: INestedProductSearchFacet, value: string }) {
		this.$store.dispatch('updateNestedCheckboxFacet', { facet, value });
	}

	handleRadioButtonChange({ facet, facetValue }: { facet: IRadioButtonProductSearchFacet, facetValue: IFacetValue }) {
		this.$store.dispatch('handleRadioButtonFacet', { facet, facetValue });
	}
}

export default ProductFacets;
