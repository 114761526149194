import { render, staticRenderFns } from "./TireStockStatus.vue?vue&type=template&id=a113229a"
import script from "./TireStockStatus.vue?vue&type=script&setup=true&lang=ts"
export * from "./TireStockStatus.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TireStockStatus.vue?vue&type=style&index=0&id=a113229a&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports