
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import UiButton from './UiButton.vue';

import { ISelectOption } from '../types/UiComponents';

import clickoutside from '../directives/click-outside';

@Component({
	name: 'UiSelect',
	components: {
		UiButton,
	},
	directives: {
		clickoutside
	},
})
export default class UiSelect extends Vue {
	// passed down to ui-button
	@Prop({ type: String })
	readonly buttonColor: string;

	@Prop({ type: Array as () => ISelectOption[] })
	readonly options: ISelectOption[];

	@Prop({ type: Object as () => ISelectOption })
	readonly value: ISelectOption;

	@Prop({ type: String, required: false, default: "" })
	readonly listTitle: string;

	selectedIndex: number = 0; // the currently checked option
	activeIndex: number =  0; // the option under the arrow key navigation
	open: boolean = false;

	get ariaListLabel(): string {
		return this.listTitle + " options";
	}

	select(index) {
		this.selectedIndex = index;
		this.toggleOpen();
	}

	close() {
		this.open = false;
	}

	toggleOpen() {
		this.open = !this.open;
		if (!this.open) {
			this.focusTrigger();
		}
	}

	focusTrigger() {
		this.$nextTick(() => {
			const triggerElement = (this.$refs.trigger as Vue).$el as HTMLButtonElement;
			triggerElement.focus();
		});
	}

	focusOptions() {
		this.$nextTick(() => {
			const optionsContainer = this.$refs.options as HTMLUListElement;
			optionsContainer.focus();
		});
	}

	getOptionNode(index) {
		const refName = `option${index}`;
		return this.$refs[refName][0];
	}

	moveToPreviousOption() {
		let nextIndex = this.activeIndex - 1;
		if (nextIndex < 0) {
			nextIndex = 0;
		}

		this.activeIndex = nextIndex;
	}

	moveToNextOption() {
		let nextIndex = this.activeIndex + 1;
		if (nextIndex >= this.options.length) {
			nextIndex = this.options.length - 1;
		}

		this.activeIndex = nextIndex;
	}

	keydown(e: KeyboardEvent) {
		const UP = 38;
		const DOWN = 40;
		const ESCAPE = 27;
		const HOME = 36;
		const END = 35;
		const ENTER = 13;

		if (e.keyCode === UP) {
			e.preventDefault();
			this.moveToPreviousOption();
		}
		if (e.keyCode === DOWN) {
			e.preventDefault();
			this.moveToNextOption();
		}
		if (e.keyCode === HOME) {
			e.preventDefault();
			this.activeIndex = 0;
		}
		if (e.keyCode === END) {
			e.preventDefault();
			this.activeIndex = this.options.length - 1;
		}
		if (e.keyCode === ESCAPE) {
			e.preventDefault();
			this.toggleOpen();
		}
		if (e.keyCode === ENTER) {
			e.preventDefault();
			this.select(this.activeIndex);
		}
	}

	getIndexOfValue(value): number {
		const index = this.options.indexOf(value);

		return index > -1 ? index : 0;
	}

	created() {
		this.selectedIndex = this.getIndexOfValue(this.value);
	}

	@Watch('open')
	watchOpen(open) {
		if (open) {
			this.activeIndex = this.selectedIndex;
			this.focusOptions();
		}
	}

	@Watch('selected')
	watchSelected(selected) {
		this.$emit('input', selected);
	}

	@Watch('value', { immediate: true })
	onValueChanged(value: ISelectOption) {
		this.selectedIndex = this.getIndexOfValue(value);
	}

	get id(): string {
		return this.options.map(({ value }) => value).join('');
	}

	get selected(): ISelectOption {
		return this.options[this.selectedIndex];
	}

	get activeDescendantId(): string | null {
		if (!this.open) return null;
		return this.getOptionNode(this.activeIndex).id;
	}
}

