
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	name: 'ProgressSpinner'
})
export default class ProgressSpinner extends Vue {

	/* Props
	============================================*/

	/* Computed
	============================================*/

	/* Data
	============================================*/

	/* Methods
	============================================*/

	/* Lifecycle Hooks
	============================================*/

}
