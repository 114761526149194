import store from './store';
import Vue from 'vue';
import VueMq from 'vue-mq';

import CategoryCarouselApp from './components/category-carousel/CategoryCarouselApp.vue';
import FacetsApp from './components/facets/FacetsApp.vue';
import FilterHeaderApp from './components/filter-header/FilterHeaderApp.vue';
import ProductListApp from './components/product-list/ProductListApp.vue';

import Checkbox from '../components/forms/Checkbox.vue';
import Icon from  '../components/Icon.vue';
import SearchBar from '../components/forms/SearchBar.vue';
import UiButton from '../components/UiButton.vue';
import VisuallyHidden from  '../components/VisuallyHidden.vue';

import ProductService from 'JS/services/ProductService';

Vue.component('UiButton', UiButton);
Vue.component('Checkbox', Checkbox);
Vue.component('SearchBar', SearchBar);
Vue.component('VisuallyHidden', VisuallyHidden);
Vue.component('Icon', Icon);

import clickOutside from '../directives/click-outside';

import BannerXApp from '../sponsored/components/BannerXApp.vue';
import { keysToCamelCase } from '../helpers/functions';

Vue.directive('click-outside', clickOutside);

Vue.use(VueMq, {
	breakpoints: {
		"mobile": 768,
		"tablet": 1024,
		"desktop": Infinity
	}
});

new Vue({
	el: "#facets-app",
	store,
	render: h => h(FacetsApp),
	created() {
		if (window && window.searchResult) {
			store.dispatch('init', { categories: window.categories, searchResult: keysToCamelCase(window.searchResult), epsilonAds: window.epsilonAds });
		}
	}
});

new Vue({
	el: "#facets-app-header",
	store,
	render: h => h(FilterHeaderApp)
});

if (window && window.searchResult) {
	new Vue({
		el: "#banner-x-app",
		store,
		render: h => h(BannerXApp)
	});

	new Vue({
		el: "#product-list-app",
		store,
		render: h => h(ProductListApp),
		provide: () => ({
			productService: new ProductService()
		}),
	});

	new Vue({
		el: "#category-carousel-app",
		store,
		render: h => h(CategoryCarouselApp),
	});
}