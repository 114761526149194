import { render, staticRenderFns } from "./VehicleIcon.vue?vue&type=template&id=7bb21c6f"
import script from "./VehicleIcon.vue?vue&type=script&lang=ts"
export * from "./VehicleIcon.vue?vue&type=script&lang=ts"
import style0 from "./VehicleIcon.vue?vue&type=style&index=0&id=7bb21c6f&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports