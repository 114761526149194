
import BatteryProductSummary, { BatteryProductCardSize } from './BatteryProductSummary.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IListableProductJson, ProductListProductClickRequest } from 'JS/types/Product';

@Component({
	name: 'BatteryList',
	components: {
		BatteryProductSummary,
	}
})
class BatteryList extends Vue {
	@Prop({ type: Array, required: true })
	products: IListableProductJson[];

	@Prop({ type: String })
	itemListId: string;

	@Prop({ type: String })
	itemListName: string;

	recordProductClick(clickRequest: ProductListProductClickRequest) {
		this.$emit('product-link-click', clickRequest);
	}

	created() {
		
	}

	get tireProductSummarySize(): BatteryProductCardSize {
		switch (this.$mq) {
			case 'mobile':
				return 'small';
			case 'tablet':
				return 'medium';
			case 'desktop':
				return 'large';
		}
	}
}

export default BatteryList;
