
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IPromoOffer } from '../../../../types/Promo';

@Component({
	name: 'PromoLabel'
})
export default class PromoLabel extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Object as () => IPromoOffer })
	readonly promo: IPromoOffer;

	/* Data
	============================================*/

	/* Methods
	============================================*/

	/* Lifecycle Hooks
	============================================*/

}
