
import Icon from 'JS/components/Icon.vue';
import LinkButton from 'JS/components/LinkButton.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { ITireProductListQuery } from 'JS/types/Catalog';

@Component({
	name: 'SizeVehicleFitment',
	components: {
		LinkButton,
		Icon
	}
})
class SizeVehicleFitment extends Vue {

	@Get('searchResult@tireQueryResult@queryParameters')
	tireQueryParameters: ITireProductListQuery;

	get tireSize() : string {
		const { sectionWidth, aspectRatio, rimSize } = this.tireQueryParameters ?? {};

		if (!sectionWidth || !aspectRatio || !rimSize) {
			return null;
		}

		return `${sectionWidth}/${aspectRatio}/${rimSize}`;
	}
}

export default SizeVehicleFitment;
