
import VisuallyHidden from '../VisuallyHidden.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'PriceDisplay',
	components: {
		VisuallyHidden,
	}
})
class ProductPriceDisplay extends Vue {
	@Prop({ type: String, required: true })
	price: string;

	get splitPrice() {
		const price = this.price.toString();
		if (!price || price.indexOf(".") === -1) return;

		const [dollars, cents] = price
			.replace('$', '')
			.split('.');

		return { dollars, cents };
	}

	get dollars(): string {
		return this.splitPrice?.dollars;
	}

	get cents(): string {
		return this.splitPrice?.cents;
	}
}

export default ProductPriceDisplay;
