
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';

import ClearAllButton from './ClearAllButton.vue';
import SelectedFacet from './SelectedFacet.vue';

import { IFacetValue, ISelectedFacetValue } from '../../../types/Search';

@Component({
	name: 'BottomBar',
	components: {
		ClearAllButton,
		SelectedFacet,
	},
})
class BottomBar extends Vue {
	@Get('selectedFacets')
	selectedFacets: ISelectedFacetValue[];

	get isMobile() {
		return (this as any).$mq === 'mobile';
	}
}
export default BottomBar;
