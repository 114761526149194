
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import UiDropdown from "../../../../components/UiDropdown.vue";

import { buildPaginationURL } from "../../../helpers/pagination";
import { range } from "../../../../utilities/array";

@Component({
	name: "PaginationPageNumbers",
	components: {
		UiDropdown
	}
})
class PaginationPageNumbers extends Vue {
	@Prop({ type: Number })
	currentPage: number;

	@Prop({ type: Number })
	numberOfPages: number;

	showDropdown = false;

	changePage(newPage: number) {
		if (newPage === this.currentPage) return;

		this.showDropdown = false;
		this.$emit("change", newPage);
	}

	isCurrent(page: number): boolean {
		return page === this.currentPage;
	}

	get pages(): number[] {
		return range(1, this.numberOfPages);
	}

	get pageLinks(): string[] {
		return this.pages.map(buildPaginationURL);
	}

	get shouldHaveDropdown(): boolean {
		return this.pages.length > 1;
	}

	get ariaExpanded(): "true" | "false" | false {
		if (!this.shouldHaveDropdown) {
			return false;
		}

		return this.showDropdown ? "true" : "false";
	}
}

export default PaginationPageNumbers;
