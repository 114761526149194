
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ITaxonomyViewModel } from '../../../types/Taxonomy';

@Component({
	name: 'CategoryList'
})
export default class CategoryList extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Array as () => ITaxonomyViewModel[], required: true })
	categories: ITaxonomyViewModel[];

	get isScrollable(): boolean {
		return this.categories.length > 10;
	}

	/* Computed
	============================================*/

}
