
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import RadioButtonLink from './RadioButtonLink.vue';

@Component({
	name: 'RadioButtonFacet',
	components: {
		RadioButtonLink,
	},
})
class RadioButtonFacet extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	radioGroupName: string;

	@Prop({ type: String, required: true })
	value: string;

	@Prop({ type: String, required: true })
	label: string;

	@Prop({ type: String, required: false })
	ariaLabel: string;

	@Prop({ type: Boolean })
	isSelected: boolean;

	@Prop({ type: Number, required: true })
	count: number;

	/* Computed
	============================================*/
	radioButtonId(): string {
		return `${this.radioGroupName}-${this.value}`;
	}

	/* Data
	============================================*/

	/* Methods
	============================================*/
	onChange(e) {
		this.$emit('change', e);
	}

	selectPrevious() {
		this.$emit('selectPrevious');
	}

	selectNext() {
		this.$emit('selectNext');
	}
}

export default RadioButtonFacet;
