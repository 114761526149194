
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ISelectedFacetValue } from '../../../types/Search';

import UiChip from '../../../components/UiChip.vue';

@Component({
	name: 'SelectedFacet',
	components: {
		UiChip,
	}
})
class SelectedFacet extends Vue {
	@Prop({ type: Object as () => ISelectedFacetValue, default: '' })
	facetValue: ISelectedFacetValue;

	remove() {
		this.$store.dispatch('deselectFacetValue', this.facetValue.value);
	}

}

export default SelectedFacet;
