
import Icon from 'Src/js/components/Icon.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getOfferIndicatorFromPromoOfferBucket, OfferBucketLabelMap, OfferIndicator } from 'JS/helpers/promo-helpers';
import { IPromoOffer } from 'Src/js/types/Promo';

@Component({
	name: 'BatteryProductPromo',
	components: {
		Icon,
	}
})
export default class BatteryProductPromo extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Object as () => IPromoOffer, required: true })
	readonly promo: IPromoOffer;

	get offerIndicator(): OfferIndicator {
		return getOfferIndicatorFromPromoOfferBucket(this.promo.promoOfferBucket);
	}

	/* Data
	============================================*/

	/* Methods
	============================================*/

	/* Lifecycle Hooks
	============================================*/

}
