
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'ProductFlag',
})
class ProductFlag extends Vue {
	@Prop({ type: String, default: 'right' })
	readonly direction: 'left' | 'right';

	@Prop({ type: String })
	readonly icon: string;

	@Prop({ type: String, default: 'red' })
	readonly color: 'red' | 'blue';

	get classes() {
		return [
			'product-flag',
			`product-flag--${this.direction}`,
			`product-flag--${this.color}`
		];
	}

	get iconClasses() {
		return [
			'icon',
			`icon-${this.icon}`
		];
	}
}

export default ProductFlag;
