
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { buildPaginationURL } from '../../../helpers/pagination';
import { range } from '../../../../utilities/array';

@Component({
	name: 'PaginationPageNumbers',
})
class PaginationPageNumbers extends Vue {
	@Prop({ type: Number })
	currentPage: number;

	@Prop({ type: Number })
	numberOfPages: number;

	changePage(newPage: number) {
		if (newPage === this.currentPage) return;

		this.$emit("change", newPage);
	}

	createPageAriaLabel(page: number): string {
		let label = `Page ${page}`;

		if (page === this.currentPage) {
			label += ', Current';
		}

		return label;
	}

	get shouldShowDotsAtBeginning(): boolean {
		const isShowingSecondPage = this.pagesToShow[1] === 2;

		return this.pages.length > 7 && !isShowingSecondPage;
	}

	get shouldShowDotsAtEnd() {
		const isShowingNextToLastPage = this.pagesToShow[this.pagesToShow.length - 2] === this.numberOfPages - 1;

		return this.pages.length > 7 && !isShowingNextToLastPage;
	}


	get pages(): number[] {
		return range(1, this.numberOfPages);
	}

	get pagesToShow() {
		if (this.pages.length <= 7) return this.pages;

		const first = this.pages[0];
		const last = this.pages[this.pages.length - 1];

		const currentIndex = this.currentPage - 1;

		let start = currentIndex - 1;
		let end = currentIndex + 2;

		if (start <= 0) {
			start = 1;
		} 

		if (currentIndex <= 3) {
			start = 1;
			end = 5;
		} else if (currentIndex >= this.numberOfPages - 4) {
			start = this.numberOfPages - 5;
			end = this.numberOfPages - 1;
		}

		return [first]
			.concat(this.pages.slice(start, end))
			.concat(last);
	}

	get displayPages() {
		const displayPages: (number | string)[] = this.pagesToShow;

		if (this.shouldShowDotsAtBeginning) {
			displayPages.splice(1, 0, 'dots');
		}

		if (this.shouldShowDotsAtEnd) {
			displayPages.splice(displayPages.length - 1, 0, 'dots');
		}

		return displayPages;
	}

	get pageLinks(): string[] {
		return this.pagesToShow.map(buildPaginationURL);
	}
}

export default PaginationPageNumbers;
