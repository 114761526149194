
import AutoImage from 'Src/js/components/automotive/AutoImage.vue';
import HoverCard from 'JS/components/HoverCard.vue';
import LazyImage from 'JS/components/LazyImage.vue';
import Price from 'Src/js/components/price/Price.vue';
import PriceDisplay from 'JS/components/price/PriceDisplay.vue';
import ReviewStars from 'JS/components/ReviewStars.vue';
import TireHeader from 'Src/js/components/automotive/tire-card/TireHeader.vue';
import TireStats from 'Src/js/components/automotive/tire-card/TireStats.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getPriceMode, IListableProductJson } from 'JS/types/Product';


// TODO: This is a temporary component until the new tire list code is merged
@Component({
	name: 'TireProductCard',
	components: {
		AutoImage,
		HoverCard,
		LazyImage,
		ReviewStars,
		PriceDisplay,
		TireHeader,
		TireStats,
		Price,
	},
})
export default class TireProductCard extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Object, required: false })
	readonly tire: IListableProductJson;

	@Prop({ type: Boolean, required: false })
	readonly small: boolean;

	/* Computed
	============================================*/

	get seasonFlagText(): string | null {
		return this.tire?.tireAttributes?.find(x => x.attributeValue === 'All Season')?.attributeValue;
	}

	get seasonFlagColor(): string {
		switch (this.seasonFlagText) {
			case 'Winter':
				return '#007299';
			case 'Summer':
				return '#975D00';
			default:
				return '#007729';
		}
	}

	/* Methods
	============================================*/

	onProductLinkClick(event: MouseEvent) {
		window.blainGtmDataLayer.push({
			'automotiveEvent': "select_recommended_tire",
			'event': "automotive_event"
		});

		window.blainGtmDataLayer.push({
			'blainNumber': this.tire.blainNumber,
			'vendorBrand': this.tire.vendorBrand,
			'productName': this.tire.productName,
			'currentPrice': this.tire.sellingPrice,
			'belowMap': this.tire.price.isBelowMAP,
			'priceMode': getPriceMode(this.tire.price),
			'itemListID': "tire_buying_guide",
			'itemListName': "Tire Buying Guide",
			'itemListIndex': 0, // TODO: Position not currently tracked
			'event': "product_list_click"
		});
	}

}

