import { icons } from 'JS/constants/icons';
import { PromoOfferBucket } from 'JS/types/Promo';

export type OfferDisplayType = 'normal' | 'giftcard' | 'freeitem' | 'rewards' | 'shipping' | 'delivery' | 'mailInRebate';

export interface OfferIndicator {
	icon: (typeof icons)[keyof typeof icons];
	color: string;
}

export const OfferBucketToDisplayTypeMap: Record<PromoOfferBucket, OfferDisplayType> = {
	[PromoOfferBucket.General]: 'normal',
	[PromoOfferBucket.Rewards]: 'rewards',
	[PromoOfferBucket.FreeItem]: 'freeitem',
	[PromoOfferBucket.GiftCard]: 'giftcard',
	[PromoOfferBucket.Shipping]: 'shipping',
	[PromoOfferBucket.Delivery]: 'delivery',
	[PromoOfferBucket.MailInRebate]: 'mailInRebate',
} as const;

export const OfferBucketLabelMap: Record<PromoOfferBucket, string> = {
	[PromoOfferBucket.General]: 'Special Offer',
	[PromoOfferBucket.Rewards]: 'Rewards Offer',
	[PromoOfferBucket.FreeItem]: 'Free Gift',
	[PromoOfferBucket.GiftCard]: 'Gift Card Offer',
	[PromoOfferBucket.Shipping]: 'Shipping Offer',
	[PromoOfferBucket.Delivery]: 'Local Delivery Offer',
	[PromoOfferBucket.MailInRebate]: 'Mail In Rebate',
};

export function getOfferIndicatorFromPromoOfferBucket(bucket: PromoOfferBucket): OfferIndicator {
	const displayType: OfferDisplayType = OfferBucketToDisplayTypeMap[bucket];
	return getOfferIndicator(displayType);
}

export function getOfferIndicator(displayType: OfferDisplayType): OfferIndicator {
	switch (displayType) {
		case 'giftcard': {
			return {
				icon: icons.giftcard,
				color: '#007FAA',
			};
		}
		case 'freeitem': {
			return {
				icon: icons.gift,
				color: '#A86700',
			};
		}
		case 'rewards': {
			return {
				icon: icons.rewards_badge,
				color: '#0054A4',
			};
		}
		case 'shipping': {
			return {
				icon: icons.truck,
				color: '#008040',
			};
		}
		case 'delivery': {
			return {
				icon: icons.localdeliver,
				color: '#008040',
			};
		}
		case 'mailInRebate': {
			return {
				icon: icons.email,
				color: '#BC3900',
			};
		}
		case 'normal':
		default: {
			return {
				icon: icons.best_price,
				color: '#7462E0',
			};
		}
	}
}