
import Vue from 'vue';
import { BCheckbox } from '../../../components/blain-form';
import { Component, Prop } from 'vue-property-decorator';
import { ILocalStoreJson } from '../../../types/LocalStore';
import { Sync } from 'vuex-pathify';

@Component({
	name: 'BopusOnlyFilter',
	components: {
		BCheckbox
	}
})
class BopusOnlyFilter extends Vue {
	@Prop({ type: Object })
	localStore: ILocalStoreJson;

	@Prop({ type: Boolean })
	value: boolean;

	@Sync('showChangeStore')
	showChangeStore: boolean;

	get bopusOnly(): boolean {
		return this.value;
	}
	set bopusOnly(checked: boolean) {
		this.$emit('input', checked);
	}

	get storeName(): string {
		return this.localStore?.cityAlias;
	}
}

export default BopusOnlyFilter;
