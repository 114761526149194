
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getWarrantyMilesPerDollar } from 'JS/helpers/tire.helpers';
import { IListableProductJson } from 'JS/types/Product';

@Component({
	name: 'TireStats',
})
class TireStats extends Vue {
	@Prop({ type: Object, required: true })
	product: IListableProductJson;

	get milesPerDollar(): string | null {
		return getWarrantyMilesPerDollar(this.product);
	}
}

export default TireStats;
