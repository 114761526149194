
import VehicleIcon from 'JS/components/automotive/VehicleIcon.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CustomerVehicleIconType, ISavedVehicle } from 'JS/types/Automotive';

@Component({
	name: 'SavedVehicle',
	components: {
		VehicleIcon
	}
})
export default class SavedVehicle extends Vue {

	/* Props
	============================================*/

	@Prop({ type: String, default: 'horizontal' })
	readonly layout: 'vertical' | 'horizontal' | 'hybrid' | 'scheduler' | 'no-icon';

	@Prop({ type: Object, required: true })
	readonly vehicle: ISavedVehicle;

	/* Computed
	============================================*/

	get showVehicle(): boolean {
		return this.vehicle.engine == null;
	}

	get line1(): string {
		const { year, make, name } = this.vehicle;
		return name ? name : `${year} ${make}`;
	}

	get line2(): string {
		const { year, make, model, name } = this.vehicle;

		return name ? `${year} ${make} ${model}` : model;
	}

	get icon(): CustomerVehicleIconType {
		return this.vehicle?.iconType ?? CustomerVehicleIconType.Car;
	}

}

