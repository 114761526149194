
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { ILocalStoreJson } from 'JS/types/LocalStore';
import { IProductEffectiveInventory } from 'JS/types/Product';

@Component({
	name: 'BatteryStockStatus',
})
export default class BatteryStockStatus extends Vue {

	@Prop({ type: Object as () => IProductEffectiveInventory, required: true })
	readonly status: IProductEffectiveInventory;

	@Get('localStore')
	readonly localStore: ILocalStoreJson;

	get storeName(): string {
		return this.localStore?.cityAlias;
	}

	get outOfStock(): boolean {
		return !this.status.isStocked || !this.status.storeOnHand || this.status.storeOnHand < 1;
	}
}
