
import BRadioGroup from 'JS/components/blain-form/BRadioGroup.vue';
import RadioButtonFacet from './RadioButtonFacet.vue';
import SearchFacetWrapper from './SearchFacetWrapper.vue';
import Vue from 'vue';
import { buildQuery, parseQueryString } from '../../helpers/url';
import { Component, Prop } from 'vue-property-decorator';
import { FacetDisplayType, IFacetValue, IRadioButtonProductSearchFacet, RatingNumberToStar } from '../../../types/Search';

@Component({
	name: 'RadioButtonFacetGroup',
	components: {
		SearchFacetWrapper,
		RadioButtonFacet,
		BRadioGroup
	}
})
class RadioButtonFacetGroup extends Vue {
	/* Props
	============================================*/
	@Prop({ type: Object as () => IRadioButtonProductSearchFacet, required: true })
	readonly facet: IRadioButtonProductSearchFacet

	@Prop({ type: Boolean })
	readonly initState: Boolean;

	@Prop({ type: String })
	readonly queryString: string;

	/* Computed
	============================================*/
	selectedValue(): IFacetValue | undefined {
		return this.facet.values.find(fv => fv.isSelected);
	}

	radioGroupName(): string {
		return `${this.facet.field}-radiogroup`;
	}

	/* Methods
	============================================*/
	getLabel(facetValue: IFacetValue): string {
		if (this.facet.field === 'avg_rating') {
			return RatingNumberToStar[facetValue.label];
		}
		return facetValue.label;
	}

	getAriaLabel(facetValue: IFacetValue): string | undefined {
		if (this.facet.field === 'avg_rating') {
			return facetValue.label;
		}
		return undefined;
	}

	onChange(isSelected: boolean, facet: IRadioButtonProductSearchFacet, facetValue: IFacetValue) {
		this.$emit('change', { facet, facetValue });
	}
}

export default RadioButtonFacetGroup;
