import { render, staticRenderFns } from "./StoreInfoCard.vue?vue&type=template&id=82dc07da"
import script from "./StoreInfoCard.vue?vue&type=script&setup=true&lang=ts"
export * from "./StoreInfoCard.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./StoreInfoCard.vue?vue&type=style&index=0&id=82dc07da&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports