
import FormFieldWrapper from '../../../components/forms/FormFieldWrapper.vue';
import { Component, Prop } from 'vue-property-decorator';
import { createBaseFormField } from 'JS/components/forms/BaseFormField';

@Component({
	name: 'RadioButtonLink',
	components: {
		FormFieldWrapper
	},
})
class RadioButtonLink extends createBaseFormField({ type: Boolean, required: true }) {

	@Prop({ type: String, required: true })
	radioGroupName: string;

	@Prop({ type: String, required: true })
	radioButtonId: string;

	@Prop({ type: Boolean })
	readonly disabled: boolean;

	@Prop({ type: String, required: false })
	readonly ariaLabel: string;

	handleChange(event) {
		if (this.disabled) return;
		this.$emit('change', !this.value);
		this.$emit('input', !this.value);
	}

	get wrapperPropsExtended() {
		return {
			...this.wrapperProps,
			hideLabel: true
		};
	}
}

export default RadioButtonLink;
