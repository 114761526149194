import { getCurrentInstance } from "vue";
import { Store } from "vuex";

/**
 * Helper composable for accessing $store on the current component instance
 * @returns The current Vuex store instance if available, null otherwise
 */
export function useLegacyVuexStore<TStoreState>(): Store<TStoreState> {
	const instance = getCurrentInstance()?.proxy;

	if (!instance) {
		console.warn("useLegacyVuexStore: called outside of a component");
		return null;
	}

	const store = instance.$store;
	if (!store) {
		console.warn("useLegacyVuexStore: no store attached");
		return null;
	}

	return store as Store<TStoreState>;
}