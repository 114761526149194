import validator from '../../utilities/validator';
import Vue, { PropOptions } from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

/** @deprecated Use blain-form instead of these legacy form components */
export function createBaseFormField<T = any>(valuePropOptions: PropOptions<T> = { required: true }) {
	@Component({
		name: 'BaseFormField',
	})
	class BaseFormField extends Vue {

		/* Props
		============================================*/

		/**
		 * Hides the label when try
		 */
		@Prop({ type: Boolean, required: false })
		readonly hideLabel: boolean;

		/**
		 * The label to display above the field
		 */
		@Prop({ type: String, required: false })
		readonly label: string;

		/**
		 * Pipe "|" delimited validation rules
		 */
		@Prop({ type: [String, Function], required: false })
		readonly rules: string | Function;

		/**
		 * The value of the field
		 */
		@Prop(valuePropOptions)
		readonly value: T;

		/* Data
		============================================*/

		canValidate: boolean = false;
		error: string | null = null;
		id: string = Math.random().toString(36).substr(2, 10);
		warning: string | null = null;

		/* Computed
		============================================*/

		get fieldStatus(): string {
			if (this.error !== null) return 'error';
			if (this.warning !== null) return 'warning';
			if (this.isValid) return 'valid';
			return '';
		}

		get icon() {
			switch (this.fieldStatus) {
				case 'error':
					return 'alert-outline';
				case 'valid':
					return 'check';
			}
		}

		get inputListeners(): Object {
			const self = this;
			return Object.assign({}, this.$listeners, {
				change(event) {
					self.$emit('change', event.target.value);
				},
				input(event) {
					self.$emit('input', event.target.value);
				}
			});
		}

		get isRequired(): boolean {
			return typeof this.rules === 'string'
				&& this.rules.indexOf('required') > -1;
		}

		get isValid(): boolean {
			return this.canValidate && this.error === null;
		}

		get readonly(): boolean {
			return this.$attrs.hasOwnProperty('readonly');
		}

		get wrapperProps() {
			return {
				hideLabel: this.hideLabel,
				label: this.label,
				error: this.error,
				required: this.isRequired
			};
		}

		/* Methods
		============================================*/

		validate(): string | null {
			let error = null;

			if (!this.canValidate || !this.rules) {
				return error;
			}

			if (typeof this.rules === 'string') {
				// @ts-ignore - Not happy about this, but the previous code had this.value typed as any.
				// keeping this code as is to prevent behavioral changes
				error = validator.validate(this.rules, this.value, this.label);
			} else if (typeof this.rules === 'function') {
				error = this.rules(this.value);
			}

			if (error !== null) {
				this.$emit('error', error);
			}

			this.error = error;

			return error;
		}

		/* Watchers
		============================================*/

		@Watch('value')
		onValueUpdated(newVal: any, oldVal: any) {
			if (newVal !== oldVal) {
				this.validate();
			}
		}

		@Watch('rules')
		onRulesUpdated(newVal: any, oldVal: any) {
			if (newVal !== oldVal) {
				this.validate();
			}
		}

	}

	return BaseFormField;
}
