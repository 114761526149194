
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

@Component({
	name: 'SearchFacetWrapper',
})
class SearchFacetWrapper extends Vue {

	/* Props
	============================================*/
	@Prop({ type: String, required: true })
	readonly label: string;

	@Prop({ type: Boolean, required: false, default: true })
	readonly collapsable: boolean;

	@Prop({ type: Boolean, default: false })
	readonly initState: boolean;

	/* Computed
		============================================*/
	get ariaExpanded() {
		if (!this.collapsable) {
			return false;
		}

		return this.isCollapsed ? 'false' : 'true';
	}

	get id() {
		const idSafeLabel = this.label.replace(/\W+/g, '');

		return `sfw-${idSafeLabel}`;
	}

	get contentID() {
		return `${this.id}-content`;
	}

	/* Data
	============================================*/
	isCollapsed: boolean = false;

	/* Methods
	============================================*/
	collapse() {
		if (this.collapsable) {
			return this.isCollapsed = !this.isCollapsed;
		}
	}

	created() {
		this.isCollapsed = this.initState;
	}

	/* Watchers
	============================================*/
	@Watch('initState')
	onInitStateChange(cur, prev) {
		this.isCollapsed = cur;
	}
}

export default SearchFacetWrapper;
