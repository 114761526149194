
import AutoImage from 'Src/js/components/automotive/AutoImage.vue';
import AutoStatsComponent from '../../../components/automotive/AutoStats.vue';
import BCheckbox from 'Src/js/components/blain-form/BCheckbox.vue';
import LazyImage from 'Src/js/components/LazyImage.vue';
import Price from 'Src/js/components/price/Price.vue';
import ProductSwatches from '../product-list/swatches/ProductSwatches.vue';
import PromoLabel from '../product-list/promos/PromoLabel.vue';
import ReviewStars from 'Src/js/components/ReviewStars.vue';
import TireHeader from 'Src/js/components/automotive/tire-card/TireHeader.vue';
import TireProductPromo from './TireProductPromo.vue';
import TireStockStatus from './TireStockStatus.vue';
import UiButton from 'JS/components/UiButton.vue';
import Vue, { PropType } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { getPriceMode, IListableProductJson, ProductListProductClickRequest } from 'Src/js/types/Product';
import { IAutoStat } from 'JS/types/Automotive';
import { ILocalStoreJson } from 'JS/types/LocalStore';

export type TireProductCardSize = 'small' | 'medium' | 'large';

@Component({
	name: 'TireProductSummary',
	components: {
		AutoImage,
		AutoStatsComponent,
		Price,
		TireStockStatus,
		ProductSwatches,
		ReviewStars,
		PromoLabel,
		LazyImage,
		BCheckbox,
		UiButton,
		TireHeader,
		TireProductPromo,
	},
})
export default class TireProductSummary extends Vue {
	@Get('localStore')
	localStore: ILocalStoreJson;

	/* Props
	============================================*/
	@Prop({ type: Object as () => IListableProductJson, required: true })
	product: IListableProductJson;

	// TODO: do we need this? these products aren't being served by hawk
	@Prop({ type: Number })
	hawkIndex: number; // used for tracking in hawk search

	@Prop({ type: Boolean })
	compare: boolean;

	@Prop({ type: Boolean })
	canCompare: boolean;

	@Prop({ type: String as PropType<TireProductCardSize>, default: 'small' })
	size: TireProductCardSize;

	@Prop({ type: Boolean })
	showStock: boolean;

	@Prop({ type: String })
	itemListId: string;

	@Prop({ type: String })
	itemListName: string;

	/* Computed
	============================================*/

	get sizeClass(): string {
		switch (this.size) {
			case 'medium':
				return 'tire-product-summary--medium';
			case 'large':
				return 'tire-product-summary--large';
			case 'small':
			default:
				return ''; // base styles; mobile-first
		}
	}

	get priceSize() {
		if (this.size === 'small') {
			return this.size;
		}

		return 'large';
	}

	get warrantyMiles(): number | null {
		const { warrantyCode } = this.product;

		if (!warrantyCode) {
			return null;
		}

		const maybeThousands = parseInt(warrantyCode.replace('K', ''));

		return isNaN(maybeThousands) || maybeThousands < 1
			? null
			: maybeThousands * 1000;
	}

	get milesPerDollar(): string | null {
		if (!this.warrantyMiles || this.product.price.hidePrice) {
			return null;
		}

		return Math.round(this.warrantyMiles / this.product.sellingPrice).toFixed();
	}

	get autoStats(): IAutoStat[] {
		const autoStats: IAutoStat[] = [];

		if (this.warrantyMiles) {
			autoStats.push({
				name: "MILE WARRANTY",
				value: this.product.warrantyCode
			});
		}

		if (this.milesPerDollar) {
			autoStats.push({
				name: "MILES PER DOLLAR",
				value: this.milesPerDollar
			});
		}

		return autoStats;
	}

	get checkboxLabel() {
		let label = `Include ${this.product.productName} in tire comparison`;
		if (!this.compare && !this.canCompare) {
			label += ' Another tire must be removed first.';
		}

		return label;
	}


	/* Data
	============================================*/
	shouldImageTransition: boolean = true;

	/* Methods
	============================================*/

	onProductLinkClick(event: MouseEvent) {
		const productTrackingRequest: ProductListProductClickRequest = {
			event,
			blainNumber: this.product.blainNumber,
			index: this.hawkIndex,
			hawkIndex: this.hawkIndex,
			vendorBrand: this.product.vendorBrand,
			productName: this.product.productName,
			currentPrice: this.product.sellingPrice,
			mapSafePrice: this.product.mapSafePrice,
			belowMap: this.product.price.isBelowMAP,
			priceMode: getPriceMode(this.product.price),
			itemListID: this.itemListId,
			itemListName: this.itemListName,
			sponsoredProductInfo: this.product.sponsoredProductInfo
		};
		this.$emit('product-link-click', productTrackingRequest);
	}
}
