
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { IListableProductJson, Inventory, IProductEffectiveInventory } from '../../../types/Product';
import { ILocalStoreJson } from '../../../types/LocalStore';

@Component({
	name: 'ProductStockInfo'
})
class ProductStockInfo extends Vue {
	@Prop({ type: Object as () => IListableProductJson })
	product: IListableProductJson;

	@Get('localStore')
	localStore: ILocalStoreJson;

	get inventory(): Inventory {
		return this.product.inventory;
	}

	get inStoreOnly(): boolean {
		return !this.product.activeOnECommerce &&
			!this.product.activeInStorePickup &&
			!this.product.activeLocalDelivery;
	}

	get onlineOnly(): boolean {
		return this.product.activeOnECommerce &&
			!this.product.activeInStorePickup &&
			!this.product.activeLocalDelivery;
	}

	get localStoreStatus(): IProductEffectiveInventory {
		return this.inventory?.[this.localStore?.storeNum];
	}

	get onlineStatus(): IProductEffectiveInventory {
		return this.inventory?.[80];
	}

	get isInStockOnline(): boolean {
		return this.product.activeOnECommerce && this.onlineStatus?.netOnHand > 0;
	}

	get isInStockForBopus(): boolean {
		return this.localStoreStatus?.netOnHand > 0;
	}

	get isInStockAtLocalStore(): boolean {
		return this.localStoreStatus?.storeOnHand > 0;
	}

	get inStoreStock(): number {
		return this.localStoreStatus?.storeOnHand;
	}

	get isAvailableForPickup(): boolean {
		return this.isInStockForBopus && this.product.activeInStorePickup;
	}

	get isAvailableForLocalDelivery(): boolean {
		return this.isInStockAtLocalStore && this.product.activeLocalDelivery;
	}

	get shippingMessage(): string {
		if (this.isInStockOnline && this.product.shippingMessage) {
			return this.product.shippingMessage;
		}

		return null;
	}

	get deliveryMessage(): string {
		if (this.isAvailableForLocalDelivery && this.product.deliveryMessage) {
			return this.product.deliveryMessage;
		}
		return null;
	}

	get storeName(): string {
		return this.localStore?.cityAlias;
	}

	get isDesktop(): boolean {
		return this.$mq === 'desktop';
	}
}

export default ProductStockInfo;
