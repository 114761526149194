import TireListRecommenderError from './TireListRecommenderError.vue';
import TireListRecommenderLoader from './TireListRecommenderLoader.vue';
import { AsyncComponentFactory } from "vue/types/options";

const TireRecommender: AsyncComponentFactory = () => {
	return {
		// https://github.com/vuejs/vue-class-component/issues/323
		component: import(/* webpackChunkName: "tire-recommender" */ 'Src/js/automotive/tire-recommendation/components/TireRecommender.vue') as any,
		loading: TireListRecommenderLoader,
		error: TireListRecommenderError,
		delay: 200,
	};
};

export default TireRecommender;