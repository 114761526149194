
import LinkButton from 'JS/components/LinkButton.vue';
import TireProductCard from 'JS/automotive/tire-recommendation/components/TireProductCard.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IListableProductJson } from 'JS/types/Product';

@Component({
	name: 'TireRecommendationsBanner',
	components: {
		LinkButton,
		TireProductCard
	}
})
export default class TireRecommendationsBanner extends Vue {

	/* Props
	============================================*/

	@Prop({ type: Array, required: true, default: () => [] })
	readonly tires: IListableProductJson[];

}

