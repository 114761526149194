
import BannerX from 'JS/sponsored/components/BannerX.vue';
import BatteryList from '../batteries/BatteryList.vue';
import Pagination from './pagination/Pagination.vue';
import ProductSummaryCard from './ProductSummaryCard.vue';
import SkeletonLoaderWrapper from './loaders/SkeletonLoaderWrapper.vue';
import TireList from '../tires/TireList.vue';
import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { EventBusEvents } from 'JS/types/EventBus';
import { Get, Sync } from 'vuex-pathify';
import { IBannerXAd } from 'JS/sponsored/sponsored-types';
import { IBatteryProductListQueryResult, IHawkSearchWebResultJson, ITireProductListQueryResult } from '../../../types/Catalog';
import { IIndexableProduct, IListableProductJson, ProductListProductClickRequest } from '../../../types/Product';
import { IQuickCartSummaryJson } from 'JS/types/Order';

const COLUMNS = {
	mobile: 1,
	tablet: 3,
	desktop: 4,
};

@Component({
	name: 'ProductListApp',
	components: {
		BatteryList,
		ProductSummaryCard,
		Pagination,
		SkeletonLoaderWrapper,
		TireList,
		BannerX
	}
})
class ProductListApp extends Vue {
	/* Computed
	============================================*/
	@Get('epsilonAds@sponsoredSearchResults')
	sponsoredProducts: IListableProductJson[];

	@Get('epsilonAds@bannerXAdTop')
	bannerX: IBannerXAd;

	@Sync('searchResult@products')
	products: IListableProductJson[];

	@Get('searchResult@tireQueryResult')
	tireQueryResult: ITireProductListQueryResult | null;

	@Get('searchResult@batteryQueryResult')
	batteryQueryResult: IBatteryProductListQueryResult | null;

	@Get('searchResult@totalProducts')
	totalSearchResults: number;

	@Get('searchResult@pageNum')
	pageNum: number;

	@Get('isBatteryList')
	isBatteryList: boolean;

	@Get('isTireList')
	isTireList: boolean;

	@Get('showProducts')
	showProducts: boolean;

	@Sync('productListTopEl')
	productListTopEl: HTMLElement;

	@Get('isLoadingSearchResult')
	isLoading: boolean;

	@Sync('productsInCart')
	productsInCart: Set<string>;

	@Sync('cartHasDeliveryItems')
	cartHasDeliveryItems: boolean;

	@Sync('cartHasPickupItems')
	cartHasPickupItems: boolean;

	get productList(): IIndexableProduct[] {
		let prodList: IIndexableProduct[] = [];
		if (!this.products || this.products.length === 0) {
			return prodList;
		}

		if (this.sponsoredProducts && this.pageNum === 1) {
			prodList = prodList.concat(this.sponsoredProducts.map(sp => {
				return {
					product: sp,
					onlyShowOnFirstPage: true,
					hawkIndex: null,
					uniqueKey: "sp" + sp.blainNumber
				};
			}));
		}

		for (let i = 0; i < this.products.length; i++) {
			prodList.push({
				product: this.products[i],
				onlyShowOnFirstPage: false,
				hawkIndex: i + 1,
				uniqueKey: "lp" + this.products[i].blainNumber
			});
		}

		return prodList;
	};

	/* Data
	============================================*/
	searchResult: IHawkSearchWebResultJson | null = null;

	/* Methods
	============================================*/
	initBusListeners(): void {
		window.vueEventBus.$on('catalog:update-products', (products: IListableProductJson[]) => this.updateProducts(products));
		window.vueEventBus.$on(EventBusEvents.UpdateQuickCart, (cartSummary: IQuickCartSummaryJson) => {
			// not storing the whole cartSummary object because that seemed to cause vue recursion errors in computed properties
			this.productsInCart = cartSummary.productsInCart.reduce((set, product) => {
				set.add(product.blainNumber);
				if (product.groupID) {
					set.add(product.groupID.toString());
				}
				return set;
			}, new Set<string>());
			this.cartHasDeliveryItems = cartSummary.hasDeliveryItems;
			this.cartHasPickupItems = cartSummary.hasPickupItems;
		});
	}

	updateProducts(products: IListableProductJson[]): void {
		this.searchResult = {
			...this.searchResult,
			products
		};
	}

	initData() {
		this.searchResult = (window as any).searchResult;
	}

	isProductInLastRow(index: number): boolean {
		const productsPerRow = COLUMNS[this.$mq];

		let productsInLastRow = this.products.length % productsPerRow;
		productsInLastRow = productsInLastRow === 0 ? productsPerRow : productsInLastRow;

		return index >= this.products.length - productsInLastRow;
	}

	isProductInLastColumn(index: number): boolean {
		const numberOfColumns = COLUMNS[this.$mq];

		return (index + 1) % numberOfColumns === 0;
	}

	/* Lifecycle Hooks
	============================================*/
	created() {
		this.initBusListeners();
		this.initData();
	}

	recordProductClick(clickRequest: ProductListProductClickRequest) {
		// @ts-ignore
		window.blainGtmDataLayer.push({
			'blainNumber': clickRequest.blainNumber,
			'vendorBrand': clickRequest.vendorBrand,
			'productName': clickRequest.productName,
			'currentPrice': clickRequest.mapSafePrice,
			'belowMap': clickRequest.belowMap,
			'priceMode': clickRequest.priceMode,
			'itemListID': clickRequest.itemListID,
			'itemListName': clickRequest.itemListName,
			'itemListIndex': clickRequest.index,
			'event': "product_list_click"
		});

		if (clickRequest.sponsoredProductInfo) {
			return navigator.sendBeacon('/event/a/sponsored-ad-click', JSON.stringify(clickRequest.sponsoredProductInfo.adID));
		} else {
		// @ts-ignore
			return HawkSearch.TrackingActions.productCatalogClick(clickRequest.event, clickRequest.hawkIndex, clickRequest.blainNumber);
		}
	}

	showInsertBanner(index: number): boolean {
		if (!this.bannerX || this.pageNum > 1) return false;
		if (this.productList.length > 2) return index == 1;
		return index == this.productList.length - 1;
	}

	mounted() {
		const topEl = this.$refs?.topOfProductList as Vue;

		this.productListTopEl = topEl?.$el as HTMLElement;
		if (this.sponsoredProducts?.length > 0) {
			this.sendSponsoredAdImpressions(
				this.sponsoredProducts.map(sp => sp.sponsoredProductInfo.adID));
		}
	}

	@Watch('sponsoredProducts')
	onUpdateSponsoredProducts(products: IListableProductJson[]) {
		if (products?.length > 0) {
			this.sendSponsoredAdImpressions(products.map(sp => sp.sponsoredProductInfo.adID));
		}
	}

	sendSponsoredAdImpressions(sponsoredAdIds: string[]) {
		navigator.sendBeacon('/event/a/sponsored-ad-impression', JSON.stringify(sponsoredAdIds));
	}
}

export default ProductListApp;
