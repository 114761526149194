
import Vue from 'vue';
import { checkWebpSupport } from '../../../../helpers/functions.js';
import { Component, Prop } from 'vue-property-decorator';
import { IProductSwatch } from '../../../../types/Product';

@Component({
	name: 'ProductSwatchThumbnail',
})
class ProductSwatchThumbnail extends Vue {
	private swatchUrl: string = null;

	@Prop({ type: Object as () => IProductSwatch })
	swatch: IProductSwatch;

	@Prop({ type: Boolean })
	isActive: boolean;

	get backgroundImgStyle(): string {
		if (this.swatchUrl) {
			return `background: url('${this.swatchUrl}');`;
		} else {
			return "";
		}
	}

	mounted() {
		const self = this;
		checkWebpSupport(function(isSupported) {
			if (isSupported) {
				self.swatchUrl = self.swatch.smallImageWebp;
			} else {
				self.swatchUrl = self.swatch.smallImage;
			}
		});
	}
}

export default ProductSwatchThumbnail;
