
import BaseButton from 'JS/components/BaseButton.vue';
import Icon from 'JS/components/Icon.vue';
import LinkButton from 'JS/components/LinkButton.vue';
import SavedVehicle from 'JS/components/automotive/SavedVehicle.vue';
import SavedVehicleMetadata from 'JS/components/automotive/SavedVehicleMetadata.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ICustomerVehicle } from 'JS/types/Automotive';

@Component({
	name: 'SelectedVehicle',
	components: {
		SavedVehicle,
		LinkButton,
		Icon,
		BaseButton,
		SavedVehicleMetadata,
	}
})
class SelectedVehicle extends Vue {
	@Prop({ type: Object, required: true })
	vehicle: ICustomerVehicle;

	@Prop({ type: Boolean, required: false })
	readonly canChangeVehicle: boolean;

	@Prop({ type: Boolean, required: false })
	readonly canRemoveVehicle: boolean;

	@Prop({ type: String, default: 'horizontal' })
	readonly layout: 'vertical' | 'horizontal' | 'hybrid';
}

export default SelectedVehicle;
