
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
	name: 'TireListRecommenderError',
})
class TireListRecommenderError extends Vue {
}

export default TireListRecommenderError;
