import { IListableProductJson } from "JS/types/Product";

export function getWarrantyMiles(product: IListableProductJson): number | null {
	if (!product?.warrantyCode) return null;

	const maybeThousands = parseInt(product.warrantyCode.replace('K', ''));

	return isNaN(maybeThousands) || maybeThousands < 1
		? null
		: maybeThousands * 1000;
}

export function getWarrantyMilesPerDollar(product: IListableProductJson): string | null {
	const miles = getWarrantyMiles(product);
	if (!miles || product.price?.hidePrice !== false) return null;
	return Math.round(miles / product.sellingPrice).toFixed();
}