
import AutoImage from 'Src/js/components/automotive/AutoImage.vue';
import AutoStatsComponent from '../../../components/automotive/AutoStats.vue';
import BatteryProductPromo from './BatteryProductPromo.vue';
import BatteryStockStatus from './BatteryStockStatus.vue';
import BCheckbox from 'Src/js/components/blain-form/BCheckbox.vue';
import LazyImage from 'Src/js/components/LazyImage.vue';
import Price from 'Src/js/components/price/Price.vue';
import ProductSwatches from '../product-list/swatches/ProductSwatches.vue';
import PromoLabel from '../product-list/promos/PromoLabel.vue';
import ReviewStars from 'Src/js/components/ReviewStars.vue';
import UiButton from 'JS/components/UiButton.vue';
import Vue, { PropType } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Get } from 'vuex-pathify';
import { getPriceMode, IListableProductJson, Inventory, IProductEffectiveInventory } from 'Src/js/types/Product';
import { IAutoStat } from 'JS/types/Automotive';
import { ILocalStoreJson } from 'JS/types/LocalStore';
import { ProductListProductClickRequest } from 'Src/js/types/Product';

export type BatteryProductCardSize = 'small' | 'medium' | 'large';

@Component({
	name: 'BatteryProductSummary',
	components: {
		AutoImage,
		AutoStatsComponent,
		Price,
		BatteryProductPromo,
		BatteryStockStatus,
		ProductSwatches,
		ReviewStars,
		PromoLabel,
		LazyImage,
		BCheckbox,
		UiButton
	},
})
export default class BatteryProductSummary extends Vue {

	/* Props
	============================================*/
	@Prop({ type: Object as () => IListableProductJson, required: true })
	product: IListableProductJson;

	// TODO: do we need this? these products aren't being served by hawk
	@Prop({ type: Number })
	hawkIndex: number; // used for tracking in hawk search

	@Prop({ type: String as PropType<BatteryProductCardSize>, default: 'small' })
	size: BatteryProductCardSize;

	@Get('localStore')
	localStore: ILocalStoreJson;

	@Prop({ type: String })
	itemListId: string;

	@Prop({ type: String })
	itemListName: string;

	/* Computed
	============================================*/

	get sizeClass(): string {
		switch (this.size) {
			case 'medium':
				return 'battery-product-summary--medium';
			case 'large':
				return 'battery-product-summary--large';
			case 'small':
			default:
				return ''; // base styles; mobile-first
		}
	}

	get priceSize() {
		if (this.size === 'small') {
			return this.size;
		}

		return 'large';
	}

	get autoStats(): IAutoStat[] {
		const autoStats: IAutoStat[] = [];

		if (this.warranty) {
			autoStats.push({
				name: "WARRANTY",
				value: this.warranty
			});
		}

		if (this.replacement) {
			autoStats.push({
				name: "FREE REPLACEMENT",
				value: this.replacement
			});
		}

		if (this.jumpstart) {
			autoStats.push({
				name: "EMERGENCY JUMPSTART",
				value: this.jumpstart
			});
		}

		return autoStats;
	}

	get warranty() {
		const warrantyAttr = this.product.batteryAttributes.find(attr => attr.attributeDescription == "Warranty" && this.product.blainNumber == attr.blainNumber);
		if (!warrantyAttr) return;
		return warrantyAttr.attributeValue[0] + ' Year';
	}

	get replacement() {
		const replacementAttr = this.product.batteryAttributes.find(attr => attr.attributeDescription == "Free Replacement" && this.product.blainNumber == attr.blainNumber);
		if (!replacementAttr) return;
		return replacementAttr.attributeValue;
	}

	get jumpstart() {
		const jumpstartAttr = this.product.batteryAttributes.find(attr => attr.attributeDescription == "Emergency Jumpstart" && this.product.blainNumber == attr.blainNumber);
		if (!jumpstartAttr) return;
		return jumpstartAttr.attributeValue;
	}

	get groupSize() {
		const groupSizeAttr = this.product.batteryAttributes.find(attr => attr.attributeDescription == "Group Size" && this.product.blainNumber == attr.blainNumber);
		if (!groupSizeAttr) return;
		return groupSizeAttr.attributeValue;
	}

	get inventory(): Inventory {
		return this.product.inventory;
	}

	get localStoreStatus(): IProductEffectiveInventory {
		return this.inventory?.[this.localStore?.storeNum];
	}



	/* Data
	============================================*/
	shouldImageTransition: boolean = true;

	/* Methods
	============================================*/

	onProductLinkClick(event: MouseEvent) {
		const productTrackingRequest: ProductListProductClickRequest = {
			event,
			blainNumber: this.product.blainNumber,
			index: this.hawkIndex,
			hawkIndex: this.hawkIndex,
			vendorBrand: this.product.vendorBrand,
			productName: this.product.productName,
			currentPrice: this.product.sellingPrice,
			mapSafePrice: this.product.mapSafePrice,
			belowMap: this.product.price.isBelowMAP,
			priceMode: getPriceMode(this.product.price),
			itemListID: this.itemListId,
			itemListName: this.itemListName,
			sponsoredProductInfo: this.product.sponsoredProductInfo
		};
		this.$emit('product-link-click', productTrackingRequest);
	}
}
