/**
 * Returns a numeric range
 *
 * @param from Start of range
 * @param to End of range (inclusive)
 */
export function range(from: number, to: number): number[] {
	const range = [];

	for (let i = from; i <= to; i++) {
		range.push(i);
	}

	return range;
}

/**
 * Returns a record of array items mapped to some key
 *
 * @param items
 * @param keyExtractor
 */
export function toRecord<T>(items: T[], keyExtractor: (item: T) => string | number): Record<string, T> {
	return items.reduce((map, item) => {
		const idKey = keyExtractor(item);
		if (map[idKey]) {
			return map;
		}

		map[idKey] = item;

		return map;
	}, {});
}

/**
 * Split an array into chunks, each having length `chunkSize`
 *
 * @param chunkSize Size of chunks in the output array. If `items.length` is not evenly divisible by `chunkSize`, then lastChunk.length < `chunkSize`. Must be > 0.
 * @param items Array of items to split into chunks
 */
export function chunks<T>(chunkSize: number, items: T[]): T[][] {
	if (chunkSize < 1) {
		throw new Error(`chunkSize must be greater than 0`);
	}

	const chunks = [] as T[][];
	for (let i = 0; i < items.length; i += chunkSize) {
		chunks.push(items.slice(i, i + chunkSize));
	}
	return chunks;
}

/**
 * Used with Array.prototype.sort, will sort in descending order
 */
export function compareDesc<T extends number | string>(a: T, b: T): number {
	return a < b
		? 1
		: a === b
			? 0
			: -1;
}