
import CloseBtn from './CloseBtn.vue';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Debounce } from '../helpers';
import { get } from 'vuex-pathify';
import { StyleValue } from 'vue/types/jsx';

@Component({
	name: 'UiDropdown',
	components: {
		CloseBtn
	}
})
export default class UiDropdown extends Vue {

	/* Props
	============================================*/

	/**
	 * Size of tip arrow
	 */
	@Prop({ type: Number, default: 16 })
	readonly arrowSize: number;

	/**
	 * Position of the dropdown
	 */
	@Prop({ type: String, default: 'bottom' })
	readonly position: string;

	/**
	 * Horizontal alignment of the dropdown
	 */
	@Prop({ type: String, default: 'center' })
	readonly horizontal: string;

	/**
	 * Flag to show or hide dropdown
	 */
	@Prop({ type: Boolean })
	readonly show: boolean;

	/**
	 * Color of tip arrow
	 */
	@Prop({ type: String, default: '#fff' })
	readonly arrowColor: string;

	/**
	 * Dropdown width
	 */
	@Prop({ type: String })
	readonly width: string;

	/**
	 * Shifts dropdown container (without tip arrow)
	 */
	@Prop({ type: Number })
	readonly shiftWidth: number;

	/**
	 * Shifts tip arrow (without dropdown container)
	 */
	@Prop({ type: Number })
	readonly shiftTip: number;

	/**
	 * Position of the close button
	 */
	@Prop({ type: String })
	readonly closeBtnPosition: string;

	/**
	 * Dropdown header icon
	 */
	@Prop({ type: String })
	readonly headerIcon: string;

	/**
	 * Dropdown header text
	 */
	@Prop({ type: String })
	readonly headerText: string;

	/**
	 * Prevents dropdown container overflow
	 * Note: this will override shiftWidth, if dropdown is overflowing
	 */
	@Prop({ type: Boolean })
	readonly preventOverflow: boolean;

	/**
	 * Custom tip arrow style class
	 */
	@Prop({ type: String, default: '' })
	readonly arrowStyle: string;

	/* Computed
	============================================*/
	get includeHeader(): boolean {
		return this.closeBtnPosition != null || this.headerIcon != null || this.headerText != null;
	};

	get dropdownStyle(): StyleValue {
		let style: StyleValue = {};
		if (this.width) {
			style = { maxWidth: this.width };
		}

		const stylePosition = this.preventOverflow ? this.responsivePosition : this.position;
		switch (stylePosition) {
			case 'bottom':
				style.bottom = `${this.positionY}px`;
				break;
			case 'top':
				style.top = `${this.positionY}px`;
				break;
			default:
				style.top = `${this.positionY}px`;
				break;
		}
		const horizontalPosition = this.preventOverflow ? this.responsiveHorizontal : this.horizontal;
		switch (horizontalPosition) {
			case 'left':
				style.left = '0px';
				break;
			case 'right':
				style.right = '0px';
				break;
			case 'center':
			default:
				style.left = `${this.centerX - this.floatingPosition}px`;
				break;
		}
		return style;
	}

	get tipArrowStyle(): string {
		const middle = Math.floor(this.arrowSize / 2) * -1;
		let style = `
			background: ${this.arrowColor};
			height: ${this.arrowSize}px;
			width: ${this.arrowSize}px;
		`;
		const stylePosition = this.preventOverflow ? this.responsivePosition : this.position;
		switch (stylePosition) {
			case 'bottom':
				style += `
					top: ${middle}px;
					left: ${this.arrowX + this.floatingPosition}px;
				`;
				break;
			case 'top':
				style += `
					bottom: ${middle}px;
					left: ${this.arrowX + this.floatingPosition}px;
				`;
				break;
			default:
				style += `
					top: ${middle}px;
					left: ${this.arrowX + this.floatingPosition}px;
				`;
				break;
		}
		return style;
	}

	/* Data
	============================================*/
	centerX: number = 0;
	centerY: number = 0;
	positionX: number = 0;
	positionY: number = 0;
	arrowX: number = 0;
	arrowY: number = 0;
	floatingPosition: number = 0;
	responsivePosition: string = 'bottom';
	responsiveHorizontal: string = 'left';

	/* Methods
	============================================*/
	calculatePosition() {
		const tip = this.$refs._dropdown as Element;
		if (!tip) return;
		const parentHeight = this.$el.clientHeight;
		const parentWidth = this.$el.clientWidth;
		const tipHeight = tip.clientHeight;
		const tipWidth = tip.clientWidth;
		this.centerY = Math.floor(((parentHeight - tipHeight) / 2));
		this.centerX = Math.floor(((parentWidth - tipWidth) / 2));
		this.positionX = (tipWidth + 20) * -1;
		this.positionY = (tipHeight + 10) * -1;
		this.arrowY = Math.ceil((tipHeight - 16) / 2);
		this.arrowX = Math.ceil((tipWidth - 10) / 2);
		if (this.shiftWidth) {
			this.arrowX += this.shiftWidth;
			this.centerX -= this.shiftWidth;
		}
		if (this.shiftTip) {
			this.arrowX += this.shiftTip;
		}
		this.floatTipContainer();
		this.$forceUpdate();
		if (this.preventOverflow) {
			this.$nextTick(() => this.verticalPositionOrDefault());
		}
	}

	floatTipContainer() {
		const boundingClient = this.$el.getBoundingClientRect() as DOMRect;
		const relativePosition = boundingClient.x;
		const { clientWidth } = this.$refs._dropdown as Element;
		const screenWith = document.documentElement.clientWidth;
		const borderAndPadding = 12;
		let margin = (screenWith - clientWidth) / 2;
		if (clientWidth < screenWith / 2) margin = margin * 2;
	}

	verticalPositionOrDefault() {
		const host = this.$refs._host as Element;
		const dropdown = this.$refs.dropdown_container as Element;
		const hostY = host.getBoundingClientRect().y;
		const dropdownHeight = dropdown.getBoundingClientRect().height;
		const isOverflowingBottom = window.outerHeight - (dropdownHeight + hostY) < 0;
		const isOverflowingTop = hostY - dropdownHeight < dropdownHeight;

		const rightOverflowDifference = window.innerWidth - dropdown.getBoundingClientRect().right;
		const isOverflowingRight = rightOverflowDifference < 0;

		const leftOverflowDifference = dropdown.getBoundingClientRect().left;
		const isOverflowingLeft = leftOverflowDifference < 0;

		if (
			isOverflowingBottom && this.responsivePosition === 'bottom'
			|| isOverflowingTop && this.responsivePosition === 'top'
		) {
			this.reverseVerticalPosition();
		}

		if (
			isOverflowingRight && this.responsiveHorizontal === 'left'
			|| isOverflowingLeft && this.responsiveHorizontal === 'right'
		) {
			this.responsiveHorizontal = 'center';
		}

		if (isOverflowingRight) {
			this.centerX = this.centerX + rightOverflowDifference;
			this.arrowX = this.arrowX - rightOverflowDifference;
		} else if (isOverflowingLeft) {
			this.centerX = this.centerX - leftOverflowDifference;
			this.arrowX = this.arrowX + leftOverflowDifference;
		}
	}

	reverseVerticalPosition() {
		if (this.responsivePosition === 'top') this.responsivePosition = 'bottom';
		else if (this.responsivePosition === 'bottom') this.responsivePosition = 'top';
	}

	resizeListener = Debounce(500, false, this.calculatePosition);

	/* Lifecycle Hooks
	============================================*/
	created() {
		this.responsivePosition = this.position;
	}

	mounted() {
		if (this.show) this.calculatePosition();
	}

	beforeDestroy() {
		if (window) window.removeEventListener('resize', this.resizeListener);
	}


	/* Watch
	============================================*/
	@Watch('show')
	onShowChange(shouldShow, wasShowing) {
		if (shouldShow === wasShowing) return;

		if (shouldShow) {
			window.addEventListener('resize', this.resizeListener);
			this.$nextTick(function() {
				this.calculatePosition();
			});
		} else {
			window.removeEventListener('resize', this.resizeListener);
		}

	}

}
