
import ProductSwatchThumbnail from './ProductSwatchThumbnail.vue';
import UiDropdown from '../../../../components/UiDropdown.vue';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { IProductSwatch } from '../../../../types/Product';

@Component({
	name: 'ProductSwatches',
	components: {
		UiDropdown,
		ProductSwatchThumbnail,
	}
})
export default class ProductSwatches extends Vue {
	readonly featuredSwatchCount = 4;

	/* Props
	============================================*/
	@Prop({ type: Array as () => IProductSwatch[], required: true })
	readonly swatches: IProductSwatch[];

	@Prop({ type: String, required: true })
	readonly activeSwatchColor: string;

	/* Computed
	============================================*/
	get featuredSwatches(): IProductSwatch[] {
		return this.swatches.slice(0, this.featuredSwatchCount);
	}

	/* Data
	============================================*/
	showSwatchDropdown: boolean = false;

	/* Methods
	============================================*/
	changeSwatch(swatch: IProductSwatch) {
		this.$emit('swatch-change', swatch);
	}

}
