
import TireComparison from './TireComparison.vue';
import TireProductSummary, { TireProductCardSize } from './TireProductSummary.vue';
import { computed, defineComponent, ref, watch } from 'vue';
import { DefaultBreakpointDefinition } from 'JS/types/Media';
import { IListableProductJson } from 'JS/types/Product';
import { ITireProductListQueryResult } from 'JS/types/Catalog';
import { objectToQueryString } from 'JS/utilities/url';
import { ProductListProductClickRequest } from 'JS/types/Product';
import { PropType } from 'vue';
import { useBreakpoints } from 'JS/composables/breakpoint';

const breakpointToTireSummarySize: { [K in keyof typeof DefaultBreakpointDefinition]: TireProductCardSize } = {
	mobile: 'small',
	tablet: 'medium',
	desktop: 'large',
};

export default defineComponent({
	name: 'TireList',
	components: {
		TireProductSummary,
		TireComparison,
	},
	props: {
		canShowStock: Boolean,
		products: { type: Array as PropType<IListableProductJson[]>, required: true },
		query: { type: Object as PropType<ITireProductListQueryResult> },
		itemListId: String,
		itemListName: String
	},
	setup(_props, { emit }) {
		const { currentBreakpoint } = useBreakpoints();
		const tireProductSummarySize = computed(() => breakpointToTireSummarySize[currentBreakpoint.value]);

		function recordProductClick(clickRequest: ProductListProductClickRequest) {
			emit('product-link-click', clickRequest);
		}

		return {
			...useTireComparison(),
			tireProductSummarySize,
			recordProductClick,
		};
	}
});

function useTireComparison() {
	const tireComparisonMap = ref<Map<string, IListableProductJson>>(new Map); // using an actual Map over an object here because order matters
	const tireComparisonTires = computed(() => Array.from(tireComparisonMap.value.values()));
	const canAddAnotherTireForComparison = computed(() => tireComparisonTires.value.length < 3);

	const initialComparisonTires: IListableProductJson[] | null = (window as any).tiresToCompare ?? [];
	initialComparisonTires.forEach(toggleCompare);

	function toggleCompare(product: IListableProductJson) {
		const { blainNumber } = product;
		if (tireComparisonMap.value.has(blainNumber)) {
			removeTireFromComparison(blainNumber);
		} else if (canAddAnotherTireForComparison.value) {
			// TODO: Maps are reactive in Vue 3, but Vue 2 requires us to create a new one
			tireComparisonMap.value = new Map(tireComparisonMap.value.set(blainNumber, product));
		}
	}

	function removeTireFromComparison(blainNumber: string) {
		tireComparisonMap.value.delete(blainNumber);
		// TODO: Maps are reactive in Vue 3, but Vue 2 requires us to create a new one
		tireComparisonMap.value = new Map(tireComparisonMap.value);
	}

	function compare() {
		const skus = tireComparisonTires.value.map(t => t.blainNumber).join(',');
		const returnUrl = window.location.href;

		window.location.href = `/tires/compare?${objectToQueryString({
			skus,
			returnUrl,
		})}`;
	}

	watch(tireComparisonTires, tires => {
		(window as any).tiresToCompare = tires;
		const url = new URL(window.location.href);

		if (!tires?.length) {
			url.searchParams.delete('compare');
		} else {
			url.searchParams.set('compare', tires.map(t => t.blainNumber).join(','));
		}

		history.replaceState({}, document.title, url.toString());
	});

	return {
		tireComparisonMap,
		tireComparisonTires,
		canAddAnotherTireForComparison,
		removeTireFromComparison,
		toggleCompare,
		compare,
	};
}
