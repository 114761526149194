
import BaseButton from 'JS/components/BaseButton.vue';
import Icon from 'JS/components/Icon.vue';
import LinkButton from 'JS/components/LinkButton.vue';
import SelectedVehicle from 'JS/components/automotive/SelectedVehicle.vue';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ICustomerVehicle } from 'JS/types/Automotive';

@Component({
	name: 'TireVehicleFitment',
	components: {
		LinkButton,
		Icon,
		BaseButton,
		SelectedVehicle
	}
})
class TireVehicleFitment extends Vue {
	@Prop({ type: Object, required: true })
	vehicle: ICustomerVehicle;

	@Prop({ type: Boolean, required: false })
	readonly hideHelpButton: boolean;

	get isMobile(): boolean {
		return this.$mq === 'mobile';
	}

	goToTiresPage() {
		window.location.href = '/tires/';
	}
}

export default TireVehicleFitment;
