
import FormFieldWrapper from './FormFieldWrapper.vue';
import { Component, Prop } from 'vue-property-decorator';
import { createBaseFormField } from './BaseFormField';

@Component({
	name: 'Checkbox',
	components: {
		FormFieldWrapper
	}
})
class Checkbox extends createBaseFormField<boolean>({ type: Boolean, required: true }) {

	@Prop({ type: Boolean })
	readonly disabled: boolean;

	@Prop({ type: Boolean })
	readonly indeterminate: boolean;

	handleChange(event) {
		if (this.disabled) return;
		this.$emit('change', event);
		this.$emit('input', !this.value);
	}

	get wrapperPropsExtended() {
		return {
			...this.wrapperProps,
			hideLabel: true
		};
	}

}

export default Checkbox;
