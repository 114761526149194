
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
	name: 'SavedVehicleMetadata',
})
export default class SavedVehicleMetadata extends Vue {
	@Prop({ type: String })
	trim: string;

	@Prop({ type: String })
	tireSize: string;

	@Prop({ type: String })
	readonly engine: string;
}
