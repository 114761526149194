import { ComponentPublicInstance, computed, ComputedRef, nextTick, onScopeDispose, ref, Ref, watch } from 'vue';
import { createListSlider, IListSlider, SlideEndListener } from 'JS/list-slider';

/**
 * Creates a list slider instance and exposes refs to attach it to component elements
 *
 * @param onSlideEnd
 */
export function useListSlider(onSlideEnd?: SlideEndListener) {
	const slider = ref<IListSlider | null>(null);
	const viewport = ref<HTMLElement | null>(null);
	const previous = ref<HTMLButtonElement | ComponentPublicInstance<any> | null>(null) as Ref<HTMLButtonElement | ComponentPublicInstance<any> | null>;
	const next = ref<HTMLButtonElement | ComponentPublicInstance<any> | null>(null) as Ref<HTMLButtonElement | ComponentPublicInstance<any> | null>;

	const previousEl = getButtonFromRef(previous);
	const nextEl = getButtonFromRef(next);

	let removeSlideListener: (() => void) | null = null;

	watch(viewport, viewport => {
		removeSlideListener?.();

		if (!viewport) {
			return;
		}

		slider.value = createListSlider(viewport);
		if (slider.value && onSlideEnd) {
			removeSlideListener = slider.value.onSlideEnd(onSlideEnd);
		}
	}, {
		immediate: true,
	});

	watch(() => [slider.value, previousEl.value, nextEl.value] as const, ([slider, previousButton, nextButton]) => {
		if (!slider) {
			return;
		}

		slider.setControls(previousButton, nextButton);
	});

	onScopeDispose(() => {
		removeSlideListener?.();
	});

	return {
		/**
		 * Viewport element ref. Ensure this is in template scope and apply it to the element you'd like as the slider viewport.
		 */
		viewport,

		/**
		 * Previous button element ref. Ensure this is in template scope and apply it to the element you'd like as the slider's previous button. Handles disabled states.
		 * Works with components so long as the component has an HTMLButtonElement as its root element
		 */
		previous,

		/**
		 * Next button element ref. Ensure this is in template scope and apply it to the element you'd like as the slider's next button. Handles disabled states.
		 * Works with components so long as the component has an HTMLButtonElement as its root element
		 */
		next,

		/**
		 * The slider instance
		 */
		slider: computed(() => {
			if (!slider.value) {
				return null;
			}

			const { setControls, onSlideEnd, ...exposed } = slider.value;

			return {
				...exposed,
				reset: () => {
					// Components may reset the slider in response to data changes that affect the items in the DOM.
					// Wait until next tick to ensure the DOM is up to date so consumers don't need to remember to
					// do it themselves
					nextTick(() => {
						exposed.reset();
					});
				}
			};
		}),
	};
}

function getButtonFromRef(buttonOrComponent: Ref<HTMLButtonElement | ComponentPublicInstance<any> | null>): ComputedRef<HTMLButtonElement | null> {
	return computed(() => {
		if (buttonOrComponent.value == null) {
			return null;
		}

		if (buttonOrComponent.value instanceof HTMLElement) {
			return buttonOrComponent.value as HTMLButtonElement;
		}

		// TODO: Vue 3 no longer has $el, so we'll need to figure out
		// a) how to expose a button ref using defineExpose
		// b) how to specify a component type that _has_ that ref exposed
		return buttonOrComponent.value.$el as HTMLButtonElement;
	});
}