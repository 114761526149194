export interface IPromoOffer {
	id?: number;
	benefitTypeID?: number;
	description: string;
	finePrint: string;
	className: string;
	linkUrl?: string;
	flagText?: string;
	promoOfferBucket: PromoOfferBucket;
}

export interface IRewardsOffer {
	blainNumbers: string[];
	daysLeft: number;
	endDate: string;
	finePrint: string;
	headerText: string;
	id: number;
	imagePath: string;
	listText: string;
	offerProducts: IOfferProduct[];
	pdpText: string;
	products: []; //TODO: Add interface
	pushNotify: boolean;
	rewardsBarcodeURL: string;
	startDate: string;
	status: string;

}

export interface IOfferProduct {
	blainNumber: string;
	rewardsOfferID: number;
}

export interface IOrderProductPromoOfferJson {
	offerID: number;
	offerType: OrderProductPromoOfferType;
	linkUrl: string;
	description: string;
	endDate: string;
	daysToExpiration: number;
	endDateString: string;
}

export enum OrderProductPromoOfferType {
	RewardsOffer = 0,
	SpecialOffer = 1,
	PassengerOffer = 2,
}

export interface IOrderPromoOfferJson {
	offerID: number;
	offerTitle: string;
	triggerType: PromoTriggerType;
	qualifies: boolean;
	qtyShortToQualify: number;
	spendAmountShortToQualify: number;
	spendAmountShortToQualifyString: string;
	applyDiscount: boolean;
	spendProgress: number;
	finePrint: string;
	hasDetails: boolean;
	hasQtyTrigger: boolean;
	hasSpendTrigger: boolean;
	noTeaser: boolean;
	isFulfillmentOffer: boolean;
	hideMessagingVariant: boolean;
	hideMessagingControl: boolean;
	ignoreRemainingSpendForMessaging: boolean;
}

export enum PromoTriggerType {
	/// Trigger is not applicable to this offer
	NoTrigger = 0,

	/// Offer qualifies at a minimum spend amount
	SpendMinimum = 1,

	/// Offer qualifies and is earned for each spend interval
	SpendInterval = 2,

	/// Order qualifies at a minimum quantity amount
	QuantityMinimum = 3,

	/// Offer qualifies and is earned for each quantity interval
	QuantityInterval = 4,

	/// Order qualifies at a minimum quantity amount
	GiftCardAmountMinimum = 5,

	/// Offer qualifies and is earned for each quantity interval
	GiftCardAmountInterval = 6,

	/// Offer qualifies according to the NCR Price Engine
	PosPriceEngine = 99
}

export interface IThresholdPromoOffer extends IOrderPromoOfferJson {
	isQtyTrigger: boolean;
	isSpendTrigger: boolean;
	isFreeItemOffer: boolean;
	isFreeItemUnavailable: boolean;
	linkUrl: string;
	singlePassengerBlainNumber: string | null;
	thumbnail: string | null;
	thumbnailAlt: string;
	thumbnailLinkUrl: string;
	valueLabel: string | null;
	fullfillmentType: string | null;
	unappliedFreeQty: number;
	isUnrestrictedStackingOffer: boolean;
}

export enum PromoOfferBucket {
	General,
	Rewards,
	FreeItem,
	GiftCard,
	Shipping,
	Delivery,
	MailInRebate,
}