
import Vue, { PropType } from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { IAutoStat } from 'JS/types/Automotive';

@Component({
	name: 'AutoStats',
})
class AutoStats extends Vue {
	@Prop({ type: Array as PropType<IAutoStat[]>, required: true })
	stats: IAutoStat[];
}

export default AutoStats;
