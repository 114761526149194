
import FormFieldWrapper from './FormFieldWrapper.vue';
import IconComponent from '../Icon.vue';
import { Component } from 'vue-property-decorator';
import { createBaseFormField } from './BaseFormField';

/**
 * A text-input styled specifically for search keyword input
 */
@Component({
	name: 'SearchBar',
	components: {
		FormFieldWrapper,
		IconComponent,
	}
})
export default class SearchBar extends createBaseFormField() { }

